import {
  Box,
  Button,
  Flex,
  HStack,
  Stack,
  Table,
  Thead,
  Tbody,
  Th,
  Td,
  TableContainer,
  Text,
  Image,
  useColorModeValue,
  Tr,
  useToast,
  Link,
  Select,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "../../../../utils/api/axios";
import { useRecoilState } from "recoil";
import { user } from "../../../../recoil/atoms/user";
import { getEvents } from "../../../../utils/actions/event";
import { isEmpty } from "lodash";
import { theme } from "../../../../styles/theme/base";
import userIcon from "../../../../static/assets/images/userIcon.svg";
import filtericon from "../../../../static/assets/images/ic_round-filter-list.png";
import secrets from "../../../../secrets";

/**
 * The UsersComponent function displays a table of attendees or organizers, with options to view
 * details or approve organizers if the user is an admin.
 * @returns The `UsersComponent` functional component is being returned.
 */

const spacing = {
  gap: 0,
  spacing: 0,
  w: "100%",
};
const GuestListOrg = () => {
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const [_, setUser] = useRecoilState(user);
  const colorValue = useColorModeValue("black.100", "gray.100");
  const organizerId = _.userData?.organizerId || _.userData?.userId;
  const token = localStorage.getItem("x-auth-token");
  // const [guestId, setGuestId] = useState(""); // State for selected event
  // const [decision, setDecision] = useState(""); // State for selected event
  const [selectedEvent, setSelectedEvent] = useState("All Events");
  const [eventUuid, setEventUuid] = useState();

  const [slug, setSlug] = useState();
  const [events, setEvents] = useState([]);

  const toast = useToast();
  const handleEventChange = (event) => {
    setSelectedEvent(event.eventName);
    setEventUuid(event.uuid);
    setSlug(event.slug);
  };
  const commonButtonStyle = {
    display: "flex",
    height: "45px",
    padding: "15px 25px",
    justifyContent: "center",
    alignItems: "center",
    gap: "5px",
    flex: "1 0 0",
    borderRadius: "100px",
  };

  const selectedButton = {
    ...commonButtonStyle,
    background: "var(--colors-shades-white-10, rgba(255, 255, 255, 0.10))",
    color: "white",
  };

  const notSelectedButton = {
    ...commonButtonStyle,
    background: "none",
    color: "#FFFFFF99",
  };

  const init = async () => {
    if (token) {
      try {
        const response = await axios.get(`/api/getGuestListByOrganizer`, {
          headers: {
            "x-auth-token": token,
          },
          params: {
            organizerId: organizerId,
            eventUuid,
          },
        });
        setData(response.data.guests);
      } catch (error) {
        console.error("Error fetching Guests:", error);
      }
    }
  };

  const getevents = async () => {
    if (token) {
      try {
        const val = await getEvents(organizerId);
        if (!isEmpty(val)) {
          console.log("checkpoint3");
          const newEvents = [];
          Object.values(val).forEach((et) => {
            newEvents.push({ ...et });
          });
          setEvents(newEvents);
        }
      } catch (error) {
        console.error("Error fetching NFTs:", error);
      }
    }
  };
  useEffect(() => {
    getevents();
  }, []);
  useEffect(() => {
    init();
  }, [eventUuid]);

  const handleDecide = async (guestId, decision) => {
    try {
      const payload = {
        guestId,
        decision,
      };
      const token = localStorage.getItem("x-auth-token");
      console.log("checkpoi");
      const guest = await axios.post("api/updateGuestlist", payload, {
        headers: {
          "x-auth-token": "Bearer " + token,
        },
      });
      console.log(guest);
      if (guest.data.success) {
        init();
      } else {
        console.log("checkpoint32");
        toast({
          title: "Error",
          description: "Try again",
          status: "error",
          isClosable: true,
          duration: 4000,
        });
      }
      // Continue with your save logic...
    } catch (error) {
      console.log("ice", error);
    }
  };

  return (
    <Box w="100%">
      {/* Additional UI for Creating Tracking Link */}
      <Box w="100%" display={"flex"} justifyContent={"space-between"}>
        <Stack
          {...{ spacing }}
          flexDir="row"
          color="#FBFAFB"
          pos={"sticky"}
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          gap={"10px"}
          flexWrap={"wrap"}
          top={0}
          p={30}
          zIndex={10}
          w="100%"
          fontWeight={700}
          bg={"rgba(0, 0, 0, 0.50)"}
          backdropFilter={"blur(150px)"}
        >
          <Box fontSize={"32px"} className="control-header-component-title">
            Marketing
          </Box>

          <Box
            sx={{
              display: "flex",
              maxWidth: "634px",
              padding: "5px",
              alignItems: "flex-start",
              borderRadius: "100px",
              border:
                "1px solid var(--border-b-primary, rgba(255, 255, 255, 0.20))",
              background: "var(--surface-s-glass, rgba(0, 0, 0, 0.50))",
              backdropFilter: "blur(75px)",
            }}
            overflow={"scroll"}
            gap={"5px"}
            className="control-header-component-filters"
          >
            <Button
              sx={notSelectedButton}
              onClick={() => {
                navigate("/attendees");
              }}
              minW={"150px"}
              p={"5px 25px"}
            >
              {" "}
              Attendees
            </Button>
            <Button sx={selectedButton} minW={"150px"} p={"5px 25px"}>
              {" "}
              Guest List
            </Button>
            <Button
              sx={notSelectedButton}
              onClick={() => {
                navigate("/trackinglinks");
              }}
              minW={"150px"}
              p={"5px 25px"}
            >
              {" "}
              Tracking Links
            </Button>
            <Button
              onClick={() => {
                navigate("/promocodes");
              }}
              sx={notSelectedButton}
              minW={"150px"}
              p={"5px 25px"}
            >
              {" "}
              Promo Codes
            </Button>
          </Box>
          <Box
            sx={theme.buttons.primary.shape}
            bg={theme.buttons.secondary.border}
            color={theme.buttons.secondary.color}
            border={theme.buttons.secondary.border}
            _hover={{ borderColor: theme.buttons.secondary.hoverBorderColor }}
            _active={{
              bg: theme.buttons.secondary.pressed,
            }}
            p="1"
            className="control-header-component-button"
          >
            <Image
              src={filtericon}
              width="1.5rem"
              height="1.5rem"
              borderRadius="var(--spacing-spacing-none, 0rem)"
            />
            <Select
              value={selectedEvent}
              border="none" // Added padding for better visual appearance
              onChange={(e) => {
                setSelectedEvent(e.target.value);
                const selectedEvent = events.find(
                  (event) => event.eventName === e.target.value
                );
                if (selectedEvent) {
                  handleEventChange(selectedEvent);
                } else {
                  setEventUuid();
                  setSlug();
                }
              }}
              width="100%" // Adjusted width to fill the entire box
            >
              <option value="All Events">All Events</option>
              {events?.map((event) => (
                <option key={event.eventUuid} value={event.eventName}>
                  {event.eventName}
                </option>
              ))}
            </Select>
          </Box>
        </Stack>
      </Box>
      <Box w="100%" minH="80vh">
        {slug && (
          <Text marginLeft="10px">
            Guest List Link:{" "}
            <Link
              cursor="pointer"
              textDecoration="underline"
              fontWeight="bold"
              onClick={() => {
                const link = `${secrets.clientUrl}/event/${slug}/guestlist`;
                navigator.clipboard.writeText(link);
              }}
            >
              {`${secrets.clientUrl}/event/${slug}/guestlist`}
            </Link>
          </Text>
        )}
        <Box w="100%" minH="80vh" px={"30px"}>
          {data ? (
            <TableContainer>
              <Table
                style={{
                  borderCollapse: "separate",
                  borderSpacing: "0 18px",
                  marginBottom: "30px",
                }}
                variant="unstyled"
              >
                <Thead
                  borderBottom="1px solid"
                  borderColor="rgba(153, 159, 187,0.2)"
                >
                  <Tr>
                    {[
                      "Name",
                      "Phone Number",
                      "Event",
                      "Instagram",
                      "Status",
                    ].map((column, index) => (
                      <Th
                        key={index}
                        paddingBottom={"20px"}
                        textTransform="capitalize"
                        color="var(--surface-surface-secondary, #959597)" // Added color
                        fontFamily="Gordita" // Added font family
                        fontSize="1rem" // Added font size
                        fontStyle="normal"
                        fontWeight={400} // Added font weight
                        lineHeight="150%" // Added line height
                        letterSpacing="-0.01rem" // Added letter spacing
                        position="relative"
                        textAlign={"center"}
                        border={"none"}
                        borderRadius="0rem" // Added border radius
                        borderRight="0px solid rgba(255, 255, 255, 0.10)" // Added border right
                        borderBottom="1px solid rgba(255, 255, 255, 0.10)" // Added border bottom
                        borderLeft="0px solid rgba(255, 255, 255, 0.10)" // Added border left
                        background="rgba(0, 0, 0, 0.50)" // Added background
                        backdropFilter="blur(75px)" // Added blur
                      >
                        {column}
                      </Th>
                    ))}
                  </Tr>
                </Thead>
                <Tbody marginBottom={"30px"}>
                  {Object.values(data)?.map(
                    (
                      {
                        guestId,
                        fullName,
                        phoneNumber,
                        eventName,
                        instagram,
                        status,
                      },
                      index
                    ) => (
                      <Tr overflow="hidden" color="white.100" key={index}>
                        <Td
                          m={0}
                          py={0}
                          pl={20}
                          border={"none"}
                          borderY={"1px solid rgba(255, 255, 255, 0.10)"}
                          borderLeft={"1px solid rgba(255, 255, 255, 0.10)"}
                          borderTopLeftRadius={"0.9375rem"}
                          borderBottomLeftRadius={"0.9375rem"}
                          overflow={"hidden"}
                          maxWidth={"15rem"}
                        >
                          <HStack py={0} spacing={10}>
                            <Image w="57px" h="57px" alt="" src={userIcon} />
                            <Text
                              className="gordita400"
                              color="#FFF" // Added color
                              fontFeatureSettings="'ss08' on, 'ss04' on" // Added font feature settings
                              fontFamily="Gordita" // Added font family
                              fontSize="1.125rem" // Added font size
                              fontStyle="normal"
                              fontWeight={700} // Added font weight
                              lineHeight="110%" // Added line height
                              letterSpacing="-0.01125rem" // Added letter spacing
                            >
                              {fullName || ""}
                            </Text>
                          </HStack>
                        </Td>
                        <Td
                          m={0}
                          py={0}
                          textAlign={"center"}
                          border={"none"}
                          borderY={"1px solid rgba(255, 255, 255, 0.10)"}
                          maxWidth={"14rem"}
                        >
                          <Text fontSize={14} className="gordita400">
                            {phoneNumber || ""}
                          </Text>
                        </Td>
                        <Td
                          m={0}
                          py={0}
                          textAlign={"center"}
                          border={"none"}
                          borderY={"1px solid rgba(255, 255, 255, 0.10)"}
                          maxWidth={"14rem"}
                        >
                          <Text fontSize={14} className="gordita400">
                            {eventName || ""}
                          </Text>
                        </Td>
                        <Td
                          m={0}
                          py={0}
                          textAlign={"center"}
                          border={"none"}
                          borderY={"1px solid rgba(255, 255, 255, 0.10)"}
                          maxWidth={"14rem"}
                        >
                          <a
                            href={`https://www.instagram.com/${instagram}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              textDecoration: "underline",
                              cursor: "pointer",
                            }}
                          >
                            @{instagram}
                          </a>
                        </Td>
                        <Td
                          m={0}
                          pl={0}
                          textAlign={"center"}
                          border={"none"}
                          borderY={"1px solid rgba(255, 255, 255, 0.10)"}
                          borderTopRightRadius={"0.9375rem"}
                          borderBottomRightRadius={"0.9375rem"}
                        >
                          {status === "Pending" ? (
                            <Flex justifyContent="center" width={"100%"}>
                              <HStack>
                                <Button
                                  onClick={() =>
                                    handleDecide(guestId, "Rejected")
                                  }
                                  sx={theme.buttons.secondary.shape}
                                  bg={theme.buttons.secondary.default}
                                  color={theme.buttons.secondary.color}
                                  _hover={{
                                    border: "1px solid #FFF",
                                    boxShadow:
                                      theme.buttons.secondary.hoverBoxShadow,
                                  }}
                                  _active={{
                                    boxShadow:
                                      theme.buttons.secondary.activeBoxShadow,
                                  }}
                                  style={{
                                    height: "38px",
                                    width: "120px",
                                    cursor: "pointer",
                                  }}
                                >
                                  Decline
                                </Button>
                                <Button
                                  onClick={() =>
                                    handleDecide(guestId, "Approved")
                                  }
                                  sx={theme.buttons.secondary.shape}
                                  bg={theme.buttons.secondary.default}
                                  color={theme.buttons.secondary.color}
                                  _hover={{
                                    border: "1px solid #FFF",
                                    boxShadow:
                                      theme.buttons.secondary.hoverBoxShadow,
                                  }}
                                  _active={{
                                    boxShadow:
                                      theme.buttons.secondary.activeBoxShadow,
                                  }}
                                  style={{
                                    height: "38px",
                                    width: "120px",
                                    cursor: "pointer",
                                  }}
                                >
                                  Accept
                                </Button>
                              </HStack>
                            </Flex>
                          ) : status === "Approved" ? (
                            <Flex justifyContent="center" width={"100%"}>
                              <Text
                                py={2}
                                // border="1px solid #FFFFFF"
                                borderRadius="50px"
                                background="rgba(81, 255, 171, 0.10)"
                                color="#51FFAB"
                                sx={theme.buttons.secondary.shape}
                                fontWeight="700"
                                style={{
                                  height: "38px",
                                  width: "120px",
                                }}
                              >
                                Accepted
                              </Text>
                            </Flex>
                          ) : (
                            <Flex justifyContent="center" width={"100%"}>
                              <Text
                                py={2}
                                // border="1px solid #FFFFFF"
                                borderRadius="50px"
                                background="rgba(255, 107, 107, 0.10)"
                                color="#FF6B6B"
                                sx={theme.buttons.secondary.shape}
                                fontWeight="700"
                                style={{
                                  height: "38px",
                                  width: "120px",
                                }}
                              >
                                Declined
                              </Text>
                            </Flex>
                          )}
                        </Td>
                      </Tr>
                    )
                  )}
                </Tbody>
              </Table>
            </TableContainer>
          ) : (
            ""
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default GuestListOrg;
