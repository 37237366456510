import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Button,
  HStack,
  Image,
  Text,
  VStack,
  Input,
  Textarea,
  Spinner,
  useDisclosure,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Modal,
  useToast,
  Flex,
  Icon
} from "@chakra-ui/react";
import { useRecoilState } from "recoil";
import { useNavigate } from "react-router-dom";

import Layout from "../../modules/layout/layout";
import { useMediaQuery } from "../../utils/useMediaQuery";
import { user } from "../../recoil/atoms/user";
import { theme } from "../../styles/theme/base";
import { userRoles } from "../../utils/constants";
import defaultProfileImage from "../../static/assets/images/defaultProfileImage.svg";
import FAQsComponent from "../portalys/faqs";
import axios from "../../utils/api/axios";
import secrets from "../../secrets";
import { fetchUserData } from "../../utils/api/registration";
import PhoneInput from "react-phone-input-2/";
import linkedin from "../../static/assets/images/socialIcon/linkedin.svg";
import twitter from "../../static/assets/images/socialIcon/twitterLight.svg";
import insta from "../../static/assets/images/socialIcon/instaLight.svg";
import ReCAPTCHA from "react-google-recaptcha";
import { userLogout } from "../../utils/api/registration";
import { FaApple,FaGoogleWallet } from 'react-icons/fa';
import appleWallet from "../../static/assets/images/US-UK_Add_to_Apple_Wallet_RGB_101421.svg";
import googleWallet from "../../static/assets/images/enUS_add_to_google_wallet_add-wallet-badge.svg";

function Settings(props) {
  const navigate = useNavigate();
  const fileInputRef = useRef();

  const isTablet = useMediaQuery("(max-width: 1192px)");
  const isMobile = useMediaQuery("(max-width: 768px)");

  const [_, setUser] = useRecoilState(user);
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [image, setImage] = useState("");
  const [isImageLoading, setisImageLoading] = useState(false);
  const [isButtonLoading, setisButtonLoading] = useState(false);
  const toast = useToast();
  const [newPhoneNumber, setNewPhoneNumber] = useState("");
  const [verificationStatus, setVerificationStatus] = useState("");
  const [verificationStatus2, setVerificationStatus2] = useState("");
  const [verificationCheck, setVerificationCheck] = useState(false);
  const [otp, setOTP] = useState("");
  const [message, setMessage] = useState("");
  const [isRecaptchaVerified, setRecaptchaVerified] = useState(false);
  const [isUpdatingPhoneNumber, setIsUpdatingPhoneNumber] = useState(false);
  const handleRecaptchaChange = (value) => {
    // Set the recaptcha verification state
    setRecaptchaVerified(true);
  };

  useEffect(() => {
    setImage(_.userData?.image);
    setPhoneNumber(_.userData?.phoneNumber);
    setFullName(
      (_?.userData.firstName || "") + " " + (_?.userData.lastName || "")
    );
    setEmail(_.userData?.email);
  }, [_]);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const isUserOrganizer =
    (_?.token && _.userData?.userRole === "ORGANIZER") ||
    _.userData?.userRole === "PREMIUM_ORGANIZER" ||
    _.userData?.userRole === "ADMIN";

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };


const handleLogout = async () => {
  try {
      const response = await userLogout(_.userData?.userId);
      console.log('Logout successful:', response);
      
  } catch (error) {
      console.error('Error during logout:', error);
      
  }
};

  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    const size = file?.size;
    const type = file?.type;

    if (type !== "image/jpeg" && type !== "image/gif" && type !== "image/png") {
      toast({
        title: "Image type must be jpg, gif or png",
        status: "warning",
        isClosable: true,
        duration: 4000,
        position: "top-right",
      });
      return;
    } else {
      uploadProfileImage(file);
    }
  };

  const addToApple = async () => {
    if (_?.token) {
      try {
        const walletAddress=_?.userData.walletAddress;
         const response= await axios.get(secrets.apiBaseUrl+"/wallet/download/"+walletAddress, {
          headers: {
            "x-auth-token": _?.token,
          },
          responseType: 'arraybuffer',
        });
        console.log('aaa',response);
        const blob = new Blob([response.data], { type: 'application/vnd.apple.pkpass' });

    // Create a URL object from the blob
    const url = window.URL.createObjectURL(blob);

    // Create an anchor element to trigger the download
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'portalys.pkpass'); // Set the filename here
    document.body.appendChild(link);

    // Initiate the download
    link.click();

    // Clean up
    window.URL.revokeObjectURL(url);
    document.body.removeChild(link);
       
      } catch (error) {
        console.error("Error downloading pass:", error);
      }
    }
  };

  const addToGoogle = async () => {
    if (_?.token) {
      try {
        const walletAddress=_?.userData.walletAddress;
         const response= await axios.get(secrets.apiBaseUrl+"/wallet/getGooglePass/"+walletAddress, {
          headers: {
            "x-auth-token": _?.token,
          },
        });
        const passUrl = response.data.passUrl;

        // Initiate the download by opening the URL in a new tab
        window.open(passUrl, "_blank");
       
      } catch (error) {
        console.error("Error downloading pass:", error);
      }
    }
  };

  const uploadProfileImage = async (image) => {
    const fd = new FormData();
    fd.append("image", image);
    fd.append("type", "profile");
    setisImageLoading(true);
    await axios
      .post("/api/uploadImage", fd,{
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        if (res.data.status) {
          if (res.data.link) {
            console.log("march", res.data.link);
            updateUser(res.data.link);
          }
        } else {
          toast({
            title: "Profile Image Uploading Err",
            status: "error",
            isClosable: true,
            duration: 4000,
            position: "top-right",
          });
        }
        setisImageLoading(false);
      })
      .catch((err) => {
        setisImageLoading(false);
        console.log("b", err);
        toast({
          title: "Profile Image Uploading Err",
          status: "error",
          isClosable: true,
          duration: 4000,
          position: "top-right",
        });
      });
  };

  const updateUser = async (link) => {
    const token = _?.token;
    await axios
      .put(
        "/api/",
        {
          image: link,
        },
        {
          headers: {
            "x-auth-token": "Bearer " + token,
          },
        }
      )
      .then((res) => {
        window.location.reload();
      })
      .catch((err) => {
        toast({
          title: "Data Updating Error",
          status: "error",
          isClosable: true,
          duration: 4000,
          position: "top-right",
        });
      });
  };

  const getUser = async (token) => {
    console.log("mwa", token);
    if (token) {
      await fetchUserData(token)
        .then((user) => {
          setUser((lp) => {
            return {
              ...lp,
              token: token,
              userData: user?.user,
            };
          });
        })
        .catch((err) => {});
    }
  };

  const handlePhoneNumberChange = (value, country, e, formattedValue) => {
    // `value` contains the raw phone number
    // You can update the state with the phone number
    setNewPhoneNumber(value);
  };

  const handleSubmit = () => {
    // Use the phoneNumber state as needed (e.g., send it to the server)
    console.log("Phone number:", phoneNumber);
  };
  const faqsList = [
    {
      key: "1",
      label: "How to access my event after buying a ticket?",
      children:
        "Log In using your account’s phone number to see your Event Wallet QR code and show it to the event organizer. Or request it on your confirmation email to receive a link by text message. By SMS, the QR code changes every 30 seconds for screenshot protection; no screenshots work 😕 . No connection? No problem, say your name and present an ID at the door, and you’re all set.",
    },
    {
      key: "2",
      label: "What happens if I buy tickets for my friends?",
      children: `All your tickets will be inside your QR code. If you need to share a ticket, you can do so easily on the "My Tickets" page. Simply enter the event, select the ticket you want to share, and choose the recipient.`,
    },
    {
      key: "3",
      label: "Can someone steal/replicate my tickets?",
      children:
        "Your tickets cannot be stolen, duplicated, or hacked. We use blockchain for the good, making it easy for everyone. Get your tickets with peace of mind. Portalys is 100% free of fraud.",
    },
    {
      key: "4",
      label: "What if I don’t see my ticket(s)?",
      children:
        "Most commonly, failed payments. Your bank did not process a payment to us.However, if you don’t see your tickets on your “My Account” page, please email hello@portalys.io with your first and last name and phone number.",
    },
    {
      key: "5",
      label: "How can I get assistance when needed?",
      children:
        "Send us a DM on our social accounts; Instagram, Twitter, and LinkedIn. Or send us an email at hello@portalys.io.",
    },
  ];

  const handleSendOTP = async (newPhoneNumber) => {
    try {
      const response = await axios.post("/api/sendOTP", {
        phoneNumber: newPhoneNumber,
      });
      if (response.data.success === true) {
        setVerificationStatus(response.data.message);
        console.log("aaaaaaaaachcekoiin");
        setVerificationCheck(true);
        setIsUpdatingPhoneNumber(false);
        return true;
      } else {
        setVerificationStatus(response.data.message);
        setVerificationCheck(false);
        return false;
      }
    } catch (error) {
      console.error("Error sending OTP:", error);
      setVerificationStatus("Failed to send OTP");
      return false;
    }
  };

  const handleVerifyOTP = async (newPhoneNumber, phoneNumber, otp) => {
    try {
      const response = await axios.post(
        "/api/updatePhoneNumber",
        { newPhoneNumber, phoneNumber, otp }
      );
      if (response.data.success === true) {
        setVerificationStatus2(response.data.message);
        setVerificationCheck(false);
        setIsUpdatingPhoneNumber(false);
        window.location.reload();
        return true;
      } else {
        setVerificationStatus2(response.data.message);
        return false;
      }
    } catch (error) {
      console.error("Error verifying OTP:", error);
      setVerificationStatus2("Failed to verify OTP");
      return false;
    }
  };

  const handleSendMessage = async () => {
    try {
      setisButtonLoading(true);
      const response = await axios.post(
         "/api/sendMessage",
        {
          fullName,
          email,
          message,
        },
        {
          headers: {
            "x-auth-token": "Bearer " + _?.token,
          },
        }
      );
      if (response.data.success === true) {
        setMessage("");
        setisButtonLoading(false);
        toast({
          title: "Message sent successfully",
          status: "success",
          description: "We got your message, we will get back to you ASAP",
          isClosable: true,
          duration: 4000,
          position: "top-right",
        });
        return true;
      } else {
        setisButtonLoading(false);
        toast({
          title: "Error sending message",
          status: "error",
          description: "Error sending message, try again later",
          isClosable: true,
          duration: 4000,
          position: "top-right",
        });
        return false;
      }
    } catch (error) {
      console.error("Error sending message:", error);
      setVerificationStatus("Failed to send message");
      return false;
    }
  };

  const handleOtpChange = (event) => {
    setOTP(event.target.value);
  };
  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };
  const handleNameChange = (event) => {
    setFullName(event.target.value);
  };
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const deleteUser = async () => {
    const userId = _?.userData?.userId;
    const token = _?.token;
    navigate("/");
    await axios
      .delete("/api/deleteAccount/" + userId, {
        headers: {
          "x-auth-token": "Bearer " + token,
        },
      })
      .then(async (res) => {
        onClose();
        setUser((lp) => {
          return {
            ...lp,
            token: "",
            userData: null,
          };
        });
        localStorage.removeItem("user_d");
        localStorage.removeItem("x-auth-token");

        toast({
          title: "Account Deleted",
          position: "bottom",
        });
      })
      .catch((err) => {
        console.log({ err });
        onClose();
      });
  };
  const ImageComponent = ({ src, additionalClass, altName, link }) => {
    return (
      <a href={link} target="_blank" rel="noopener noreferrer">
        <img src={src} className={additionalClass} alt={altName} />
      </a>
    );
  };
  const socialMediaItems = [
    {
      source: twitter,
      name: "twitter",
      link: "https://twitter.com/PortalysEvents",
    },
    {
      source: linkedin,
      name: "linkedin",
      link: "https://www.linkedin.com/company/portalys-io/",
    },
    {
      source: insta,
      name: "instagram",
      link: "https://www.instagram.com/portalys.io/",
    },
  ];
  return (
    <Layout {...props}>
      <Box
        maxW={"100%"}
        padding={isTablet ? "0 1.31rem" : "0"}
        margin={"0 auto 5.31rem"}
      >
        <Text
          textAlign={"center"}
          margin={isMobile ? "2.19rem auto 3.06rem" : "4.06rem auto"}
          fontSize={isMobile ? "1.75rem" : "2.375rem"}
          className="gordita600"
          lineHeight={"150%"}
          letterSpacing={"-0.02375rem"}
          textTransform={"capitalize"}
        >
          Settings
        </Text>
        <VStack>
        {isMobile &&(

<Flex marginTop={"10px"} justify="center" direction="row" align="center" gap={"10px"} marginBottom={'20px'}>
<Box
      onClick={addToApple}
    >
      <Image
        src={appleWallet}
        style={{
          width: "15.75rem",
          //height: "1.5rem",
          minHeight:"48px"
        }}
      />
    </Box>
<Box
      onClick={addToGoogle}
    >
      <Image
        src={googleWallet}
        style={{
          width: "19.75rem",
          //height: "1.5rem",
          minHeight:"48px"
        }}
      />
    </Box>
    </Flex>
)}

          <HStack spacing="0.94rem">
            {isImageLoading ? (
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                borderRadius={"full"}
                bg="linear-gradient(180deg, #1A1A1A 0%, rgba(26, 26, 26, 0) 100%)"
                width={"100%"}
                height={"100%"}
              >
                <Spinner size="lg" />
              </Box>
            ) : (
              <Image
                borderRadius="4.8125rem"
                width="4.8125rem"
                height="4.8125rem"
                src={image || defaultProfileImage}
              />
            )}
            <Button
              sx={theme.buttons.secondary.shape}
              bg={theme.buttons.secondary.bgColor}
              color={theme.buttons.secondary.color}
              border={theme.buttons.secondary.border}
              onClick={handleButtonClick}
              _hover={{
                borderColor: theme.buttons.secondary.hoverBorderColor,
              }}
              _active={{
                bg: theme.buttons.secondary.pressed,
              }}
              borderRadius={"20px"}
              style={{
                maxWidth: "17.5rem",
                width: "100%",
                height: "3.125rem",
              }}
              textTransform={"capitalize"}
            >
              Change Profile Photo
            </Button>{" "}
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: "none" }}
              onChange={handleFileInputChange}
            />
          </HStack>
        </VStack>
        <VStack marginTop={isMobile ? "4.38rem" : "5.97rem"}>
          {!isUpdatingPhoneNumber && !verificationCheck && (
            <>
              <Text
                fontSize={"1.8125rem"}
                className="gordita600"
                lineHeight={"150%"}
                letterSpacing={"-0.01813rem"}
                textTransform={"capitalize"}
                mb={"1.87rem"}
              >
                {phoneNumber}
              </Text>
              <Button
                sx={theme.buttons.secondary.shape}
                bg={theme.buttons.secondary.bgColor}
                color={theme.buttons.secondary.color}
                border={theme.buttons.secondary.border}
                onClick={() => setIsUpdatingPhoneNumber(true)}
                _hover={{
                  borderColor: theme.buttons.secondary.hoverBorderColor,
                }}
                _active={{
                  bg: theme.buttons.secondary.pressed,
                }}
                borderRadius={"20px"}
                style={{
                  maxWidth: "23.75rem",
                  width: "100%",
                  height: "3.125rem",
                  margin: 0,
                }}
                textTransform={"capitalize"}
              >
                Change Phone Number
              </Button>

              {isMobile && (
                <Button
                  sx={theme.buttons.secondary.shape}
                  bg={theme.buttons.secondary.bgColor}
                  color={theme.buttons.secondary.color}
                  border={theme.buttons.secondary.border}
                  onClick={() => {
                    setUser((lp) => {
                      return {
                        ...lp,
                        token: "",
                        userData: null,
                      };
                    });
                    localStorage.removeItem("user_d");
                    localStorage.removeItem("x-auth-token");
                    handleLogout();
                    navigate("/");
                  }}
                  _hover={{
                    borderColor: theme.buttons.secondary.hoverBorderColor,
                  }}
                  _active={{
                    bg: theme.buttons.secondary.pressed,
                  }}
                  borderRadius={"20px"}
                  style={{
                    maxWidth: "23.75rem",
                    marginTop: "30px",
                    width: "100%",
                    height: "3.125rem",
                    //margin: 0,
                  }}
                  textTransform={"capitalize"}
                >
                  Log Out
                </Button>
              )}
            </>
          )}
          {isUpdatingPhoneNumber && (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <PhoneInput
                  inputProps={{
                    name: "newPhoneNumber",
                    placeholder: "Enter Your New Phone Number",
                    style: {
                      width: "380px",
                      mb: "25px",
                      border: "1px solid #FFFFFF",
                      borderRadius: "10px", // Adjusted to match your styling
                      height: "55px", // Adjusted to match your styling
                      fontSize: "14px",
                      color: "#FFFFFF",
                      backgroundColor: "transparent",
                      borderColor: "rgba(255, 255, 255, 0.20)",
                    },
                  }}
                  style={{
                    marginTop: "0.30rem",
                    marginBottom: "25px", // Adjusted to match your styling
                  }}
                  dropdownStyle={{ backgroundColor: "#000000" }}
                  country={"1"}
                  value={newPhoneNumber}
                  onChange={handlePhoneNumberChange}
                />
              </div>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: 700,
                  color: "green",
                }}
              >
                {verificationStatus}
              </p>

              <Button
                type="submit"
                className="gordita700"
                sx={theme.buttons.primary.shape}
                bg={theme.buttons.primary.default}
                color={theme.buttons.primary.color}
                css={{
                  width: "380px",
                }}
                _hover={{
                  bg: theme.buttons.primary.hover,
                  boxShadow: theme.buttons.primary.hoverBoxShadow,
                }}
                _active={{
                  boxShadow: theme.buttons.primary.activeBoxShadow,
                }}
                onClick={() => handleSendOTP("+" + newPhoneNumber)}
              >
                Receive SMS With The Code
              </Button>

              <Button
                type="submit"
                className="gordita700"
                sx={theme.buttons.primary.shape}
                bg={theme.buttons.secondary.default}
                color={theme.buttons.secondary.color}
                css={{
                  width: "380px",
                }}
                _hover={{
                  //  bg: theme.buttons.secondary.hover,
                  boxShadow: theme.buttons.secondary.hoverBoxShadow,
                }}
                _active={{
                  boxShadow: theme.buttons.secondary.activeBoxShadow,
                }}
                onClick={() => setIsUpdatingPhoneNumber(false)}
              >
                Cancel
              </Button>
            </>
          )}

          {!isUpdatingPhoneNumber && verificationCheck && (
            <>
              <Input
                placeholder="Enter OTP"
                fontSize={14}
                border="1px solid"
                borderRadius="10px"
                borderColor="rgba(255, 255, 255, 0.20)"
                type="text"
                color="#FFFFFF"
                focusBorderColor="#FFFFFF"
                _hover={{
                  borderColor: "#FFFFFF !important",
                }}
                _placeholder={{
                  color: "rgba(255, 255, 255, 0.40)",
                }}
                h="55px"
                w={"380px"}
                mb={"25px"}
                value={otp}
                onChange={handleOtpChange}
              />

              <p
                style={{
                  textAlign: "center",
                  fontWeight: 700,
                  color: "red",
                }}
              >
                {verificationStatus2}
              </p>
              <Button
                type="submit"
                className="gordita700"
                sx={theme.buttons.primary.shape}
                bg={theme.buttons.primary.default}
                color={theme.buttons.primary.color}
                css={{
                  width: "380px",
                }}
                _hover={{
                  bg: theme.buttons.primary.hover,
                  boxShadow: theme.buttons.primary.hoverBoxShadow,
                }}
                _active={{
                  boxShadow: theme.buttons.primary.activeBoxShadow,
                }}
                onClick={() =>
                  handleVerifyOTP("+" + newPhoneNumber, phoneNumber, otp)
                }
              >
                Verify OTP
              </Button>

              <Button
                type="submit"
                className="gordita700"
                sx={theme.buttons.primary.shape}
                bg={theme.buttons.secondary.default}
                color={theme.buttons.secondary.color}
                css={{
                  width: "380px",
                }}
                _hover={{
                  //  bg: theme.buttons.secondary.hover,
                  boxShadow: theme.buttons.secondary.hoverBoxShadow,
                }}
                _active={{
                  boxShadow: theme.buttons.secondary.activeBoxShadow,
                }}
                onClick={() => setVerificationCheck(false)}
              >
                Cancel
              </Button>
            </>
          )}
        </VStack>

        <VStack mt={isMobile ? "4.38rem" : "6.25rem"}>
          <FAQsComponent faqsList={faqsList} />
        </VStack>

        <VStack
          maxW={"580px"}
          w={"100%"}
          margin={isMobile ? "5.12rem auto" : "6.75rem auto"}
        >
          <Text
            fontSize={isMobile ? "1.4375rem" : "2.375rem"}
            textTransform={"capitalize"}
            className="gordita600"
            textAlign={"center"}
            mb={"2.19rem"}
          >
            Need Assistance? Reach Out!
          </Text>

          <VStack
            width={"100%"}
            margin={"0 !important"}
            marginBottom={"1.25rem !important"}
            gap={"0.94rem"}
          >
            <Input
              placeholder="Name"
              fontSize={14}
              border="1px solid"
              borderRadius="10px"
              borderColor="rgba(255, 255, 255, 0.20)"
              type="text"
              color="#FFFFFF"
              focusBorderColor="#FFFFFF"
              _hover={{
                borderColor: "#FFFFFF !important",
              }}
              _placeholder={{
                color: "rgba(255, 255, 255, 0.40)",
              }}
              h="55px"
              w={"100%"}
              value={fullName}
              onChange={handleNameChange}
            />
            <Input
              placeholder="Email"
              fontSize={14}
              border="1px solid"
              borderRadius="10px"
              borderColor="rgba(255, 255, 255, 0.20)"
              type="text"
              color="#FFFFFF"
              focusBorderColor="#FFFFFF"
              _hover={{
                borderColor: "#FFFFFF !important",
              }}
              value={email}
              onChange={handleEmailChange}
              _placeholder={{
                color: "rgba(255, 255, 255, 0.40)",
              }}
              h="55px"
              w={"100%"}
              margin={"0 !important"}
            />

            <Textarea
              bg="#000000"
              borderRadius="10px"
              className="gordita400"
              placeholder={"Message"}
              border="1px solid rgba(255, 255, 255, 0.20)"
              size="sm"
              minH="199px"
              w={"100%"}
              color="#FFFFFF"
              focusBorderColor="#FFFFFF"
              _hover={{
                borderColor: "#FFFFFF",
              }}
              _placeholder={{
                color: "rgba(255, 255, 255, 0.40)",
                fontSize: 16,
              }}
              margin={"0 !important"}
              value={message}
              onChange={handleMessageChange}
              // maxLength={250}
            />
          </VStack>
          <ReCAPTCHA
            sitekey={secrets.recaptchaSiteKey} // Replace with your actual site key
            onChange={handleRecaptchaChange} // Add a function to handle recaptcha changes
            theme="light"
          />

          <Button
            transition="all 0.2s ease-in-out"
            bg={theme.buttons.primary.default}
            color={theme.buttons.primary.color}
            _hover={{
              bg: theme.buttons.primary.hover,
              boxShadow: theme.buttons.primary.hoverBoxShadow,
            }}
            _active={{
              boxShadow: theme.buttons.primary.activeBoxShadow,
            }}
            css={{
              height: "3.125rem",
              fontsize: "1rem",
            }}
            className="gordita700"
            w={"100%"}
            borderRadius={"3.125rem"}
            onClick={handleSendMessage}
            disabled={!message || !fullName || !email || !isRecaptchaVerified}
            isLoading={isButtonLoading}
          >
            Send
          </Button>
        </VStack>
        {isMobile && (
          <>
            <VStack
              maxW={"580px"}
              w={"100%"}
              margin={isMobile ? "5.12rem auto" : "6.75rem auto"}
            >
              <div className="social-icons-container">
                {socialMediaItems?.map(({ source, name, link }, index) => (
                  <Button
                    key={index}
                    bg={theme.buttons.tercary.bgColor}
                    color={theme.buttons.tercary.color}
                    _hover={{ bg: theme.buttons.tercary.hoverBgColor }}
                    _active={{
                      bg: theme.buttons.tercary.pressedBgColor,
                    }}
                    css={{
                      width: "fit-content",
                      padding: 0,
                      borderRadius: "100px",
                    }}
                  >
                    <ImageComponent
                      src={source}
                      altName={`${name}-icon`}
                      additionalClass={"social-icon"}
                      link={link}
                    />
                  </Button>
                ))}
              </div>
            </VStack>

            <VStack marginTop={isMobile ? "4.38rem" : "8.81rem"} gap={"2.5rem"}>
              <Text
                style={{
                  color: "var(--Neutral-Honest-White, #FFF)",
                  fontFamily: "Gordita",
                  fontSize: "18px",
                  fontStyle: "normal",
                  fontWeight: 700,
                  lineHeight: "150%", // or '27px' if you prefer
                  letterSpacing: "-0.18px",
                }}
              >
                hello@portalys.io
              </Text>
            </VStack>
          </>
        )}

        <VStack marginTop={isMobile ? "4.38rem" : "8.81rem"} gap={"2.5rem"}>
          <Text
            fontSize={isMobile ? "1.4375rem" : "1.8125rem"}
            className="gordita600"
            lineHeight={"150%"}
            letterSpacing={"-0.01813rem"}
            textTransform={"capitalize"}
            textAlign={"center"}
          >
            Want to remove your account?
          </Text>
          <Button
            sx={theme.buttons.secondary.shape}
            bg={theme.buttons.secondary.bgColor}
            color={theme.buttons.secondary.color}
            border={theme.buttons.secondary.border}
            // onClick={() => setIsUpdatingPhoneNumber(true)}
            _hover={{
              borderColor: theme.buttons.secondary.hoverBorderColor,
            }}
            _active={{
              bg: theme.buttons.secondary.pressed,
            }}
            borderRadius={"20px"}
            style={{
              maxWidth: "22.0625rem",
              width: "100%",
              height: "3.125rem",
              margin: 0,
            }}
            textTransform={"capitalize"}
            onClick={() => {
              onOpen();
            }}
          >
            Delete Account
          </Button>
        </VStack>
        <Modal isOpen={isOpen} onClose={onClose} isCentered={true}>
          <ModalOverlay />
          <ModalContent
            bg={"rgba(0, 0, 0, 0.50)"}
            backdropFilter={"blur(150px)"}
            borderRadius={"20px"}
            border={"1px solid #333333"}
          >
            <ModalHeader color="white.100">Cancel</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              Are you sure you want to delete your account? This action cannot
              be undone.
            </ModalBody>
            <ModalFooter>
              <Button
                mr={3}
                onClick={onClose}
                sx={theme.buttons.primary.shape}
                bg={theme.buttons.secondary.default}
                color={theme.buttons.secondary.color}
                style={{
                  width: "fit-content",
                }}
                _hover={{ bg: theme.buttons.secondary.hover }}
              >
                Cancel
              </Button>
              <Button
                sx={theme.buttons.primary.shape}
                bg={theme.buttons.primary.default}
                color={theme.buttons.primary.color}
                style={{
                  width: "5rem",
                  padding: "0",
                }}
                _hover={{
                  bg: theme.buttons.primary.hover,
                  boxShadow: theme.buttons.primary.hoverBoxShadow,
                }}
                _active={{
                  boxShadow: theme.buttons.primary.activeBoxShadow,
                }}
                onClick={deleteUser}
              >
                OK
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Box>
    </Layout>
  );
}

export default Settings;
