import {
    Box,
    HStack,
    Stack,
    Table,
    Thead,
    Tbody,
    Th,
    Td,
    TableContainer,
    Text,
    Image,
    Tr,
    useColorModeValue,
    Link,
    Flex,
    Button,
    useToast,
  } from "@chakra-ui/react";
  import { useEffect, useState } from "react";
  import { useNavigate } from "react-router-dom";
  import axios from "../../../../utils/api/axios";
  import secrets from "../../../../secrets";
  import { useRecoilState } from "recoil";
  import { user } from "../../../../recoil/atoms/user";
  import userIcon from "../../../../static/assets/images/userIcon.svg";
  import Pagination from "../../../layout/dashboard/pagination";
  import { userRoles } from "../../../../utils/constants";
  const spacing = {
    gap: 0,
    spacing: 0,
    w: "100%",
  };
  
  /**
   * The UsersComponent function displays a table of attendees or organizers, with options to view
   * details or approve organizers if the user is an admin.
   * @returns The `UsersComponent` functional component is being returned.
   */
  const OrganizersComponent = () => {
    const [data, setData] = useState([]);
    const navigate = useNavigate();
    const type ="ORGANIZER" 
    const [_, setUser] = useRecoilState(user);
    const colorValue = useColorModeValue("black.100", "gray.100");
    const [currentPage, setCurrentPage] = useState(1);
    const toast = useToast();
  
    const getUsers = async () => {
      let url = "/api/getAllAttendees/" + type;
      await axios
        .get(url, {
          headers: {
            "x-auth-token": "Bearer " + _?.token,
          },
        })
        .then((res) => {
          setData(res.data?.user);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    useEffect(() => {
      if (type) getUsers();
    }, [type]);
  
    const approving = async (userId) => {
      let url = "/api/approveOrganizer/" + userId;
      await axios
        .get(url, {
          headers: {
            "x-auth-token": "Bearer " + _?.token,
          },
        })
        .then((res) => {
          getUsers();
          toast({
            title: "Organizer Approved",
            status: "success",
            isClosable: true,
            duration: 4000,
            position: "bottom",
          });
        })
        .catch((err) => {
          console.log(err);
        });
    };

    const disapproving = async (userId) => {
      let url =  "/api/disapproveOrganizer/" + userId;
      await axios
        .get(url, {
          headers: {
            "x-auth-token": "Bearer " + _?.token,
          },
        })
        .then((res) => {
          getUsers();
          toast({
            title: "Organizer Disapproved",
            status: "success",
            isClosable: true,
            duration: 4000,
            position: "bottom",
          });
        })
        .catch((err) => {
          console.log(err);
        });
    };
  
    return (
      <Box w="100%">
        <Box w="100%" minH="80vh">
          <Stack {...{ spacing }} flexDir="row">
            <HStack justifyContent="flex-end" {...{ spacing }} spacing={12} flex={1}></HStack>
          </Stack>
          <Box mt={56} w="100%">
              <TableContainer>
                <Table style={{ borderCollapse: "separate", borderSpacing: "0 1em" }} variant="unstyled">
                  <Thead borderBottom="1px solid" borderColor="rgba(153, 159, 187,0.2)">
                    <Tr>
                      {["Name", "Email",  "Action"].map((column, index) => {
                        return (
                          <Th textTransform="capitalize" key={index} fontSize={16} color={colorValue}>
                            {column}
                          </Th>
                        );
                      })}
                    </Tr>
                  </Thead>
                  <Tbody>
                    {Object.values(data)
                    ?.filter(object => object.organizerId === null)
                    ?.map(
                      (
                        { userId, image, firstName, lastName, email, activeEvents, totalEvents, isOrganizerApproved },
                        index
                      ) => {
                        return (
                          <Tr borderRadius="6px" bg="#1C1C1C" color="white.100" key={index}>
                            <Td py={0} h="57px">
                              <HStack py={0} spacing={10}>
                                <Image w="57px" h="57px" alt="" src={image || userIcon} />
                                <Text className="gordita400" fontSize={16}>
                                  {`${firstName || ""} ${lastName || ""}`}
                                </Text>
                              </HStack>
                            </Td>
                            <Td>
                              <Text fontSize={14} className="gordita400">
                                {email || ""}
                              </Text>
                            </Td>
                           
                            <Td>
                              {_.userData?.userRole === "ADMIN" ? (
                                <Flex justifyContent="center" w="100%" gap="10">
                                  {!isOrganizerApproved ? (
                                    <Button
                                      onClick={() => approving(userId)}
                                      bg="primary.100"
                                      borderRadius="25px"
                                      className="gordita400"
                                      color="black.300"
                                    >
                                      Approve
                                    </Button>
                                  ) : (
                                    <Button
                                      bg="primary.100"
                                      disabled={true}
                                      borderRadius="25px"
                                      className="gordita400"
                                      color="black.300"
                                    >
                                      Approved
                                    </Button>
                                  )}
                                                                    
                                  {isOrganizerApproved ? (
                                    <Button
                                      onClick={() => disapproving(userId)}
                                      bg="primary.100"
                                      borderRadius="25px"
                                      className="gordita400"
                                      color="black.300"
                                    >
                                      Disapprove
                                    </Button>
                                  ) : (
                                    <Button
                                      bg="primary.100"
                                      disabled={true}
                                      borderRadius="25px"
                                      className="gordita400"
                                      color="black.300"
                                    >
                                      Disapproved
                                    </Button>
                                  )}
                                  <Link
                                    px="30px"
                                    py={2}
                                    border="1px solid"
                                    borderColor="primary.100"
                                    fontSize={14}
                                    w="max"
                                    borderRadius="25px"
                                    className="gordita400"
                                    color="primary.100"
                                    onClick={() => navigate(`${userId}`)}
                                  >
                                    View Detail
                                  </Link>
                                </Flex>
                              ) : (
                                <Link
                                  px="30px"
                                  py={2}
                                  border="1px solid"
                                  borderColor="primary.100"
                                  fontSize={14}
                                  w="max"
                                  borderRadius="25px"
                                  className="gordita400"
                                  color="primary.100"
                                >
                                  View
                                </Link>
                              )}
                            </Td>
                          </Tr>
                        );
                      }
                    )}
                  </Tbody>
                </Table>
              </TableContainer>
          </Box>
        </Box>

      </Box>
    );
  };
  
  export default OrganizersComponent;