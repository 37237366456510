import {
  Box,
  Button,
  Image,
  Input,
  Text,
  useToast,
  Spinner,
  Heading,
} from "@chakra-ui/react";
import axios from "../../utils/api/axios";
import { React, useState, useRef, useEffect } from "react";
import { useRecoilState } from "recoil";
import { user } from "../../recoil/atoms/user";
import facebook from "../../static/assets/images/fb.svg";
import defaultProfileImage from "../../static/assets/images/defaultProfileImage.svg";
import "../../styles/profile/profile.css";
import secrets from "../../secrets";
import { fetchUserData } from "../../utils/actions/registration";
import { userRoles } from "../../utils/constants";
import withAuth from "../../utils/withAuth";
import { theme } from "../../styles/theme/base";
import { useMediaQuery } from "../../utils/useMediaQuery";
import EventLayout from "../../modules/layout/event";
import { useSearchParams, useLocation } from "react-router-dom";
import { NotificationComponent } from "../../modules/shared/notificationComponent";
import { useNavigate, useParams } from "react-router-dom";
/**
 * The Settings function is a React component that renders a form for users to update their profile
 * information, including their name, email, website, social media links, and profile image.
 * @param props - An object containing the properties passed down to the Settings component.
 * @returns A functional component named "Settings" is being returned.
 */
function Account(props) {
  const isMobile = useMediaQuery("(max-width: 768px)");
  const isTablet = useMediaQuery("(max-width: 1024px)");
  const [show, setShow] = useState(1);
  const [_, setUser] = useRecoilState(user);
  let [searchParams, setSearchParams] = useSearchParams();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [organizationName, setOrganizationName] = useState("");
  const [website, setWebsite] = useState("");
  const [socialLinks, setSocialLinks] = useState([]);
  const [instagramHandle, setInstagramHandle] = useState("");
  const [twitterHandle, setTwitterHandle] = useState("");
  const [linkedinHandle, setLinkedinHandle] = useState("");
  const [image, setImage] = useState("");
  const navigate = useNavigate();

  const [isLoading, setLoading] = useState(false);
  const [isImageLoading, setisImageLoading] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [loadingStripe, setLoadingStripe] = useState(false);
  const [loadingHubspot, setLoadingHubspot] = useState(false);
  const [isHubspot, setIsHubspot] = useState(false);
  const [isStripe, setIsStripe] = useState(false);

  const toast = useToast();
  const organizerId = _.userData?.organizerId || _.userData?.userId;
  const [isCollaborator, setIsCollaborator] = useState(false);

  useEffect(() => {
    getUser();
    setFirstName(_.userData?.firstName);
    setLastName(_.userData?.lastName);
    setOrganizationName(_.userData?.organizationName);
    setImage(_.userData?.image);
    setWebsite(_.userData?.website);
    setSocialLinks(_.userData?.socialLinks);
    if (_.userData?.socialLinks) {
      try {
        const socialLinksObj = _.userData.socialLinks;

        // Check if Instagram handle exists
        if (socialLinksObj.instagram) {
          setInstagramHandle(socialLinksObj.instagram);
        }

        // Check if Twitter handle exists
        if (socialLinksObj.twitter) {
          setTwitterHandle(socialLinksObj.twitter);
        }
        if (socialLinksObj.linkedin) {
          setLinkedinHandle(socialLinksObj.linkedin);
        }
      } catch (error) {
        console.error("Error parsing socialLinks JSON:", error);
      }
    }
    stripeCheck();

    if (_.userData?.organizerId) {
      setIsCollaborator(true);
    }
  }, []);

  useEffect(() => {
    const socialLinksObj = _.userData.socialLinks;
    //console.log('aaaaa',instagramHandle);
    //console.log('bbbbb',_.userData.socialLinks.in);

    if (
      firstName !== _.userData?.firstName ||
      lastName !== _.userData?.lastName ||
      website !== _.userData?.website ||
      image !== _.userData?.image ||
      instagramHandle !== socialLinksObj?.instagram ||
      linkedinHandle !== socialLinksObj?.linkedin ||
      twitterHandle !== socialLinksObj?.twitter
    ) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
  }, [
    firstName,
    lastName,
    website,
    image,
    instagramHandle,
    twitterHandle,
    linkedinHandle,
  ]);
  const hubspot = searchParams.get("hubspot");

  useEffect(() => {
    hubspotCheck();

    if (hubspot) {
      toast({
        position: "top-right",
        duration: 4000,
        render: ({ onClose }) => (
          <NotificationComponent
            title={"Hubspot Connected Successfully"}
            status={"success"}
            onClose={onClose}
          />
        ),
      });
      navigate("/account");
    }
  }, [hubspot]);

  const fileInputRef = useRef();

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    const size = file?.size;
    const type = file?.type;

    if (type !== "image/jpeg" && type !== "image/gif" && type !== "image/png") {
      toast({
        title: "Image type must be jpg, gif or png",
        status: "warning",
        isClosable: true,
        duration: 4000,
        position: "top-right",
      });
      return;
    } else {
      uploadProfileImage(file);
    }
  };

  const uploadProfileImage = async (image) => {
    const fd = new FormData();
    fd.append("image", image);
    fd.append("type", "profile");
    setisImageLoading(true);
    await axios
      .post("/api/uploadImage", fd,{
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        if (res.data.status) {
          if (res.data.link) {
            setImage(res.data.link);
          }
        } else {
          toast({
            title: "Profile Image Uploading Err",
            status: "error",
            isClosable: true,
            duration: 4000,
            position: "top-right",
          });
        }
        setisImageLoading(false);
      })
      .catch((err) => {
        setisImageLoading(false);
        toast({
          title: "Profile Image Uploading Err",
          status: "error",
          isClosable: true,
          duration: 4000,
          position: "top-right",
        });
      });
  };

  const getUser = async () => {
    const token = _?.token;
    if (token) {
      await fetchUserData(token)
        .then((user) => {
          setUser((lp) => {
            return {
              ...lp,
              token,
              userData: user?.user,
            };
          });
        })
        .catch((err) => {});
    }
  };

  const updateUser = async () => {
    const token = _?.token;
    setLoading(true);
    setIsButtonDisabled(true);
    await axios
      .put(
        "/api/",
        {
          firstName,
          lastName,
          image,
          socialLinks: JSON.stringify({
            instagram: instagramHandle,
            twitter: twitterHandle,
            linkedin: linkedinHandle,
          }),
          website,
        },
        {
          headers: {
            "x-auth-token": "Bearer " + token,
          },
        }
      )
      .then((res) => {
        getUser();
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        toast({
          title: "Data Updating Error",
          status: "error",
          isClosable: true,
          duration: 4000,
          position: "top-right",
        });
      });
    // Reload the current page
    //window.location.reload();
  };

  const connectStripe = async () => {
    const token = _?.token; // Make sure you have the correct token

    try {
      const response = await axios.get(
         "/api/authorize", // Note that we removed "/api" from the path
        {
          headers: {
            "x-auth-token": "Bearer " + token,
          },
          params: {
            userId: organizerId,
          },
        }
      );

      // Redirect the user to the Stripe Connect onboarding
      window.location.href = response.data.redirectUrl;
    } catch (error) {
      console.error("Error setting up payments:", error);
      // Handle error and provide user feedback
    }
  };
  const viewStripe = async () => {
    window.open("https://dashboard.stripe.com/", "_blank");
  };

  const installHubspot = async () => {
    const token = _?.token; // Make sure you have the correct token

    try {
      const response = await axios.get(
         "/hubspot/install", 
        {
          headers: {
            "x-auth-token": "Bearer " + token,
          },
          params: {
            userId: organizerId,
          },
        }
      );

      // Redirect the user to the Stripe Connect onboarding
      window.location.href = response.data.redirectUrl;
    } catch (error) {
      console.error("Error installing hubspot:", error);
      // Handle error and provide user feedback
    }
  };

  const viewHubspot = async () => {
    window.open("https://app.hubspot.com/", "_blank");
  };

  const hubspotCheck = async () => {
    const token = _?.token;

    try {
      const response = await axios.get(
       "/hubspot/isHubspot", 
        {
          headers: {
            "x-auth-token": "Bearer " + token,
          },
          params: {
            userId: organizerId,
          },
        }
      );

      // Redirect the user to the Stripe Connect onboarding
      setIsHubspot(response.data.status);
    } catch (error) {
      console.error("Error installing hubspot:", error);
      // Handle error and provide user feedback
    }
  };

  const stripeCheck = async () => {
    const token = _?.token;

    try {
      const response = await axios.get("/api/onboarded", {
        headers: {
          "x-auth-token": "Bearer " + token,
        },
        params: {
          userId: organizerId,
        },
      });

      // Redirect the user to the Stripe Connect onboarding
      setIsStripe(response.data.status);
    } catch (error) {
      console.error("Error installing hubspot:", error);
      // Handle error and provide user feedback
    }
  };

  const payout = async () => {
    const token = _?.token; // Make sure you have the correct token

    try {
      await axios.post(
         "/api/payout", // Note that we removed "/api" from the path
        {
          headers: {
            "x-auth-token": "Bearer " + token,
          },
          params: {
            userId: organizerId,
          },
        }
      );

      // Redirect the user to the Stripe Connect onboarding
    } catch (error) {
      console.error("Error processing payout", error);
      // Handle error and provide user feedback
    }
  };

  const InputLabel = ({ label, isDisabled }) => {
    return (
      <Text
        fontSize="0.875rem"
        color={isDisabled ? "#FFFFFF99" : "#FFFFFF"}
        fontWeight="700"
        marginLeft={".94rem"}
      >
        {label}
      </Text>
    );
  };

  return (
    <EventLayout {...props}>
      {!isCollaborator ? (
        <>
          <Heading
            color="#FBFAFB"
            fontSize="2rem"
            pos={"sticky"}
            top={0}
            zIndex={40}
            w="100%"
            fontWeight={700}
            mb={"3.12rem"}
            bg={"rgba(0, 0, 0, 0.50)"}
            backdropFilter={"blur(150px)"}
            borderBottom={"1px solid rgba(255, 255, 255, 0.10)"}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Box
              maxW={"1200px"}
              width={"100%"}
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
              margin={"0 auto"}
              padding={"30px"}
              flexWrap={"wrap"}
              gap={"10px"}
            >
              <Box w={"fit-content"}>Account</Box>

              <Box
                display="flex"
                width={"fit-content"}
                gap={"10px"}
                flexWrap={"wrap"}
              >
                <Button
                  sx={theme.buttons.primary.shape}
                  bg={theme.buttons.secondary.border}
                  color={theme.buttons.secondary.color}
                  border={theme.buttons.secondary.border}
                  _hover={{
                    borderColor: theme.buttons.secondary.hoverBorderColor,
                  }}
                  style={{
                    width: "fit-content",
                  }}
                  disabled={loadingHubspot}
                  isLoading={loadingHubspot}
                  onClick={isHubspot ? viewHubspot : installHubspot}
                >
                  {isHubspot ? "View HubSpot" : "Connect HubSpot"}
                </Button>
                <Button
                  sx={theme.buttons.primary.shape}
                  bg={theme.buttons.secondary.border}
                  color={theme.buttons.secondary.color}
                  border={theme.buttons.secondary.border}
                  _hover={{
                    borderColor: theme.buttons.secondary.hoverBorderColor,
                  }}
                  style={{
                    width: "fit-content",
                  }}
                  disabled={loadingStripe}
                  isLoading={loadingStripe}
                  onClick={isStripe ? viewStripe : connectStripe}
                >
                  {isStripe ? "View Stripe Account" : "Setup Stripe"}
                </Button>

                <Button
                  style={{ width: "fit-content", padding: "0 1.5rem" }}
                  sx={theme.buttons.primary.shape}
                  bg={theme.buttons.primary.default}
                  color={theme.buttons.primary.color}
                  _hover={{
                    bg: isButtonDisabled
                      ? theme.buttons.primary.default
                      : theme.buttons.primary.hover,
                    boxShadow: isButtonDisabled
                      ? ""
                      : theme.buttons.primary.hoverBoxShadow,
                  }}
                  _active={{
                    boxShadow: theme.buttons.primary.activeBoxShadow,
                  }}
                  onClick={() => updateUser()}
                  disabled={isButtonDisabled}
                  isLoading={isLoading}
                  loadingText="Updating"
                >
                  Save
                </Button>
              </Box>
            </Box>
          </Heading>
          {/* form */}
          <Box
            background="black"
            px={"30px"}
            paddingBottom="11.25rem"
            display={"flex"}
            placeItems={"center"}
            flexDir={"column"}
            w={"100%"}
          >
            <Box
              display={"flex"}
              placeItems={"center"}
              flexDir={"column"}
              gap="2.8rem"
              marginTop="30px"
              maxW={"26rem"}
            >
              <Box display={"flex"} placeItems={"center"} gap={"1.25rem"}>
                {isImageLoading ? (
                  <Box
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    borderRadius={"full"}
                    bg="linear-gradient(180deg, #1A1A1A 0%, rgba(26, 26, 26, 0) 100%)"
                    width={"5.5625rem"}
                    height={"5.5625rem"}
                  >
                    <Spinner size="lg" />
                  </Box>
                ) : (
                  <Image
                    width={"5.5625rem"}
                    height={"5.5625rem"}
                    objectFit="cover"
                    src={image || defaultProfileImage}
                    borderRadius={"full"}
                  />
                )}
                <Button
                  sx={theme.buttons.primary.shape}
                  bg={theme.buttons.secondary.border}
                  color={theme.buttons.secondary.color}
                  border={theme.buttons.secondary.border}
                  _hover={{
                    borderColor: theme.buttons.secondary.hoverBorderColor,
                  }}
                  style={{ width: "19.1875rem" }}
                  className="gordita700"
                  onClick={handleButtonClick}
                >
                  Change Profile Photo
                </Button>{" "}
                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  onChange={handleFileInputChange}
                />
              </Box>

              <Box
                display={"flex"}
                placeItems={"center"}
                flexDir={"column"}
                gap={"1.87rem"}
                width={"100%"}
              >
                <Box w={"100%"}>
                  <InputLabel label={"First Name"} />
                  <Input
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    size="lg"
                    marginTop=".3rem"
                    color="white.100"
                    borderRadius={"0.625rem"}
                  />
                </Box>

                <Box w={"100%"}>
                  <InputLabel label={"Last Name"} />
                  <Input
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    size="lg"
                    marginTop=".3rem"
                    color={"white.100"}
                    borderRadius={"0.625rem"}
                  />
                </Box>

                <Box w={"100%"}>
                  <InputLabel label={"Email"} isDisabled={true} />
                  <Input
                    value={_?.userData?.email}
                    color="white.100"
                    disabled={true}
                    size="lg"
                    marginTop=".3rem"
                    borderRadius={"0.625rem"}
                  />
                </Box>

                {/*<Box w={"100%"}>
              <InputLabel label={"Organization Name"} />
              <Input
                value={organizationName}
                onChange={(e) => setLastName(e.target.value)}
                size="lg"
                marginTop=".3rem"
                color="white.100"
                borderRadius={"0.625rem"}
              />
            </Box>*/}

                <Box w={"100%"}>
                  <InputLabel label={"Website"} />
                  <Input
                    value={website}
                    onChange={(e) => setWebsite(e.target.value)}
                    placeholder="Enter website"
                    size="lg"
                    marginTop=".3rem"
                    color="white.100"
                    borderRadius={"0.625rem"}
                  />
                </Box>

                <Box w={"100%"}>
                  <InputLabel label={"Instagram"} />
                  <Input
                    value={instagramHandle}
                    onChange={(e) => setInstagramHandle(e.target.value)}
                    placeholder="Enter Instagram handle without the '@'"
                    onKeyPress={(e) => {
                      if (e.key === " ") {
                        e.preventDefault();
                      }
                    }}
                    size="lg"
                    marginTop=".3rem"
                    color="white.100"
                    borderRadius={"0.625rem"}
                  />
                </Box>

                <Box w={"100%"}>
                  <InputLabel label={"LinkedIn"} />
                  <Input
                    value={linkedinHandle}
                    onChange={(e) => setLinkedinHandle(e.target.value)}
                    placeholder="Enter LinkedIn profile link"
                    onKeyPress={(e) => {
                      if (e.key === " ") {
                        e.preventDefault();
                      }
                    }}
                    size="lg"
                    marginTop=".3rem"
                    color="white.100"
                    borderRadius={"0.625rem"}
                  />
                </Box>

                <Box w={"100%"}>
                  <InputLabel label={"Twitter"} />
                  <Input
                    value={twitterHandle}
                    onChange={(e) => setTwitterHandle(e.target.value)}
                    placeholder="Enter Twitter handle without the '@'"
                    onKeyPress={(e) => {
                      if (e.key === " ") {
                        e.preventDefault();
                      }
                    }}
                    size="lg"
                    marginTop=".3rem"
                    color="white.100"
                    borderRadius={"0.625rem"}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </>
      ) : (
        <>
          <Heading
            color="#FBFAFB"
            fontSize="2rem"
            pos={"sticky"}
            top={0}
            zIndex={40}
            w="100%"
            fontWeight={700}
            mb={"3.12rem"}
            bg={"rgba(0, 0, 0, 0.50)"}
            backdropFilter={"blur(150px)"}
            borderBottom={"1px solid rgba(255, 255, 255, 0.10)"}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Box
              maxW={"1200px"}
              width={"100%"}
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
              margin={"0 auto"}
              padding={"0 30px"}
            >
              <Box
                w={"100%"}
                display={"flex"}
                justifyContent={"flex-start"}
                alignItems={"center"}
                p={"1.75rem 0"}
              >
                <div>Account</div>
              </Box>
            </Box>
          </Heading>
          {/* form */}
          <Box
            background="black"
            paddingBottom="11.25rem"
            display={"flex"}
            placeItems={"center"}
            flexDir={"column"}
            w={"100%"}
          >
            You don't have access to this page. Only the workspace owner in your
            organization has access to this page.
          </Box>
        </>
      )}
    </EventLayout>
  );
}
export default withAuth(Account, [
  userRoles.ORGANIZER,
  userRoles.PREMIUM_ORGANIZER,
  userRoles.ADMIN,
]);
