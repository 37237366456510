/**
 * This is a module that exports functions for fetching and posting events data from an API.
 * @param payload - The payload parameter in the postEvent function is an object containing the data to
 * be added as a new event. It could include properties such as the event name, date, location,
 * description, and other relevant information.
 * @returns This code exports several functions that make API calls to retrieve or post events data.
 * Each function returns an object that contains the data retrieved from the API, with some additional
 * properties added using the spread operator. If an error occurs during the API call, the function
 * logs the error to the console.
 */
import {
  addEvent,
  fetchEvent,
  fetchEventByUUID,
  fetchVerifierEvent,
  fetchAllEventLiked,
  fetchEventBySlug,
  editEvent
} from "../api/events";
export const postEvent = async (payload,token) => {
  try {
    const res = await addEvent(payload,token);
    const data = res.data;
    return {
      ...data,
    };
  } catch (error) {
    console.log(error);
  }
};
export const modifyEvent = async (payload,token) => {
  try {
    const res = await editEvent(payload,token);
    const data = res.data;
    return {
      ...data,
    };
  } catch (error) {
    console.log(error);
  }
};

export const getEvents = async (params,token) => {
  try {
    const res = await fetchEvent(params,token);
    const data = res.data;
    return {
      ...data,
    };
  } catch (error) {
    console.log(error);
  }
};
export const getVerifierEvents = async (params) => {
  try {
    const res = await fetchVerifierEvent(params);
    const data = res.data;
    return {
      ...data,
    };
  } catch (error) {
    console.log(error);
  }
};




export const getAllEventsLiked = async (params,token) => {
  try {
    const res = await fetchAllEventLiked(params,token);
    const data = res.data;

    return {
      ...data,
    };
  } catch (error) {
    console.log(error);
  }
};

export const getEventsByUUID = async (id) => {
  try {
    const res = await fetchEventByUUID(id);
    const data = res.data;
    return {
      ...data,
    };
  } catch (error) {
    console.log(error);
  }
};

export const getEventsBySlug = async (id, trackingId) => {
  try {
    const res = await fetchEventBySlug(id, trackingId);
    const data = res.data;
    return {
      ...data,
    };
  } catch (error) {
    console.log(error);
  }
};
