import {
  Box,
  HStack,
  useColorMode,
  useColorModeValue,
  Stack,
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  VStack,
  Button as CBtn,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Image,
  Link,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Text,
  DrawerHeader,
} from "@chakra-ui/react";
import LightLogo from "../../static/assets/images/logo.svg";
import {
  InstagramOutlined,
  LinkedinOutlined,
  TwitterOutlined,
} from "@ant-design/icons";
import DarkLogo from "../../static/assets/images/logoBlack.svg";
import darkOrganizeIcon from "../../static/assets/images/darkOrganizeIcon.svg";
import typeMenuIcon from "../../static/assets/images/TypeMenu.svg";
import lightOrganizeIcon from "../../static/assets/images/lightOrganizeIcon.svg";
import {
  menuIcon,
  menuIconLight,
  portalysGreen,
} from "../../static/assets/images";
import userIcon from "../../static/assets/images/userIcon.svg";
import { theme } from "../../styles/theme/base";
import { NavbarWrapper } from "../../styles/layout/navbar";
import Typography from "./typography";
import { navLinks, userRoles } from "../../utils/constants";
import { useMediaQuery } from "../../utils/useMediaQuery";
import { colorMode as colorModeImage } from "../../static/assets/images";
import {
  RegistraionModalTypes,
  registration,
} from "../../recoil/atoms/registration";
import { useRecoilState } from "recoil";
import SignUp from "../pages/SignUp";
import SignUpOrganizer from "../pages/SignUpOrganizer";
import Verifier from "../pages/Verifier";
import Login from "../pages/Login";
import Password from "../pages/Password";
import ForgotPassword from "../pages/ForgotPassword";
import { useEffect, useState, useRef, Fragment } from "react";
import { isEmpty } from "lodash";
import { user } from "../../recoil/atoms/user";
import { useNavigate } from "react-router-dom";
import { Button } from "antd";
import defaultProfileImage from "../../static/assets/images/defaultProfileImage.svg";
import SignUpVerification from "../pages/SignUpVerification";
import CheckEmail from "../pages/CheckEmail";
import EmailVerification from "../pages/EmailVerification";
import LoginVerification from "../pages/LoginVerification";
import LoginVerificationByEmail from "../pages/LoginByEmailVerification";
import LoginByEmail from "../pages/LoginByEmail";
import { userLogout } from "../../utils/api/registration";
import { useLocation, Link as LinkTo } from "react-router-dom"; // Assuming you are using React Router

const Navbar = ({ logout, login, provider, embed }) => {
  const isMobile = useMediaQuery("(max-width: 768px)");
  const isLaptop = useMediaQuery("(max-width: 1320px)");
  const isTablet = useMediaQuery("(max-width: 950px)");
  const xsmall = useMediaQuery("(max-width: 520px)");
  const navigate = useNavigate();
  const [btnText, setBtnText] = useState(false);
  const [_, setRegistrationModal] = useRecoilState(registration);
  const [_U, setUser] = useRecoilState(user);
  const [navbar, setNavbar] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [isOrganizer, setOrganizer] = useState(0);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const { colorMode, toggleColorMode } = useColorMode();

  const organizerCheck = window.location.pathname.includes("organizer");

  const [isVisible, setIsVisible] = useState(false);
  const dropdownRef = useRef(null);
  const location = useLocation();
  const isUserOrganizer =
    _U?.token &&
    _U?.userData?.userId &&
    (_U.userData?.userRole === "ORGANIZER" ||
      _U.userData?.userRole === "PREMIUM_ORGANIZER" ||
      _U.userData?.userRole === "ADMIN");

  const isUserAttendee =
    _U?.token && _U?.userData?.userId && _U.userData?.userRole === "ATTENDEE";
  useEffect(() => {
    // Get the URL search params
    const searchParams = new URLSearchParams(location.search);

    // Check if 'qrcode' parameter exists and is set to 'true'
    if (searchParams.get("qrcode") === "true") {
      if (!isUserAttendee && !isUserOrganizer) {
        console.log("nooooo", _U?.token);
        setRegistrationModal((lp) => {
          return {
            ...lp,
            openModal: true,
            modalType: RegistraionModalTypes.SIGNUP,
          };
        });
      } else {
        setRegistrationModal((lp) => {
          return { ...lp, openModal: false, userRole: "" };
        });
        if (!isTablet) {
          alert(
            "QR Code only available on mobile! Click on the link from your mobile device"
          );
        }
      }
    }
  }, [location.search, _U.token]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsVisible(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => {
    setIsVisible(!isOpen);
  };

/**
 * Handles the user logout process.
 * @async
 * @function handleLogout
 */
  const handleLogout = async () => {
    try {
      const response = await userLogout( _U?.userData?.userId);
        console.log('Logout successful:', response);
        
    } catch (error) {
        console.error('Error during logout:', error);
        
    }
  };

  /* The above code is defining a function called `renderModal` that returns a different component
  based on the value of the `_.modalType` variable. The `switch` statement checks the value of
  `_.modalType` against different cases and returns a corresponding component. The components
  returned are `Login`, `SignUp`, `Password`, `ForgotPassword`, or `Password` depending on the case.
  The `const value` line is not related to the `renderModal` function and is likely defining a
  variable for use elsewhere in the code. */
  const value = useColorModeValue(
    theme.colors.white[100],
    theme.colors.black[100]
  );
  const renderModal = () => {
    switch (_.modalType) {
      case RegistraionModalTypes.LOGIN:
        return <Login />;
      case RegistraionModalTypes.SIGNUP:
        return <SignUp />;
      case RegistraionModalTypes.SIGNUPORGANIZER:
        return <SignUpOrganizer />;
      case RegistraionModalTypes.PASSWORD:
        return <Password />;
      case RegistraionModalTypes.RESET_PASSWORD:
        return <Password />;
      case RegistraionModalTypes.FORGOT:
        return <ForgotPassword />;

      case RegistraionModalTypes.VERIFIER:
        return <Verifier />;
      case RegistraionModalTypes.EMAIL_VERIFICATION:
        return <EmailVerification />;

      case RegistraionModalTypes.SIGNUP_VERIFICATION:
        return <SignUpVerification />;
      case RegistraionModalTypes.LOGIN_VERIFICATION:
        return <LoginVerification />;
      case RegistraionModalTypes.CHECK_EMAIL:
        return <CheckEmail />;
      case RegistraionModalTypes.LOGIN_EMAIL:
        return <LoginByEmail />;
      case RegistraionModalTypes.LOGIN_VERIFICATION_EMAIL:
        return <LoginVerificationByEmail />;
    }
  };

  useEffect(() => {
    if (provider !== null) {
      setBtnText(true);
    } else {
      setBtnText(false);
    }
  }, [provider]);

  useEffect(() => {
    const handleScroll = () => {
      const maxScroll = document.body.scrollHeight - window.innerHeight;
      const currentScroll = window.pageYOffset;
      setScrollPosition((currentScroll / maxScroll) * 100);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const MenuButton = ({ onClickFunc, label }) => {
    return (
      <CBtn
        onClick={() => onClickFunc()}
        sx={theme.buttons.tercary.shape}
        bg={theme.buttons.tercary.bgColor}
        color={theme.buttons.tercary.color}
        _hover={{ bg: theme.buttons.tercary.hoverBgColor }}
        _active={{
          bg: theme.buttons.tercary.pressedBgColor,
        }}
        css={{
          width: "100% !important",
        }}
        className="gordita700"
      >
        {label}
      </CBtn>
    );
  };

  /* The below code is rendering a navigation bar component with various links and buttons. It also
 includes a drawer menu for mobile devices and a modal for user registration. The component uses
 props such as colorMode, navLinks, and setUser to customize its appearance and functionality. */
  return (
    <>
      <Box
        p="0 1.25rem"
        w="100%"
        h={isMobile ? "75px" : "90px"}
        pos="fixed"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        // bg={"rgba(0, 0, 0, 0.50)"}
        backdropFilter={"blur(150px)"}
        hidden={embed}
      >
        <Box h="100%" w="100%" maxWidth="73.625rem" margin={"0 auto"}>
          <HStack justifyContent="space-between" h="100%">
            <HStack h="100%">
              <Image
                onClick={() => navigate("/")}
                cursor="pointer"
                alt=""
                src={LightLogo}
              />
            </HStack>

            {isMobile && isUserOrganizer && (
              <CBtn
                sx={theme.buttons.secondary.shape}
                bg={theme.buttons.secondary.border}
                color={theme.buttons.secondary.color}
                border={theme.buttons.secondary.border}
                _hover={{
                  borderColor: theme.buttons.secondary.hoverBorderColor,
                }}
                _active={{
                  bg: theme.buttons.secondary.pressed,
                }}
                style={{ width: "140px" }}
                onClick={() =>
                  navigate("/dashboard")
                }
              >
                Enter Control
              </CBtn>
            )}
            <HStack display={isMobile ? "none" : "flex"} h="100%">
              <HStack gap={"10px"}>
                {!isUserAttendee && !isUserOrganizer ? (
                  <>
                    <HStack gap={"0.30rem"}>
                      <CBtn
                       onClick={() => navigate("/create-event")}
                        sx={theme.buttons.secondary.shape}
                        bg={theme.buttons.secondary.bgColor}
                        color={theme.buttons.secondary.color}
                        css={{
                          width: "fit-content",
                        }}
                        _hover={{
                          borderColor: theme.buttons.secondary.hoverBorderColor,
                        }}
                        _active={{
                          bg: theme.buttons.secondary.pressed,
                        }}
                        border={theme.buttons.secondary.border}
                        className="gordita700"
                      >
                        Create Your Event!
                      </CBtn>
                      <CBtn
                        onClick={() => {
                          setRegistrationModal((lp) => {
                            return {
                              ...lp,
                              openModal: true,
                              modalType: RegistraionModalTypes.SIGNUP,
                            };
                          });
                        }}
                        sx={theme.buttons.primary.shape}
                        bg={theme.buttons.primary.default}
                        color={theme.buttons.primary.color}
                        css={{
                          width: "fit-content",
                        }}
                        _hover={{
                          bg: theme.buttons.primary.hover,
                          boxShadow: theme.buttons.primary.hoverBoxShadow,
                        }}
                        _active={{
                          boxShadow: theme.buttons.primary.activeBoxShadow,
                        }}
                        className="gordita700"
                      >
                        Log In/Sign Up
                      </CBtn>
                    </HStack>
                  </>
                ) : (
                  <>
                    <CBtn
                      onClick={toggleDropdown}
                      sx={theme.accountButton.primary.shape}
                      bg={theme.accountButton.primary.default}
                      color={theme.accountButton.primary.color}
                      _hover={{
                        borderColor: theme.accountButton.primary.hover,
                      }}
                      style={{
                        width: "50px",
                        height: "50px",
                        padding: "5px",
                        borderRadius: "50%",
                      }}
                    >
                      <Image
                        alt="user-image"
                        style={{
                          borderRadius: "50%",
                          width: "40px",
                          height: "40px",
                          margin: 0,
                          padding: 0,
                        }}
                        src={_U?.userData?.image || defaultProfileImage}
                      />
                    </CBtn>
                  </>
                )}

                {isVisible && (
                  <Box
                    ref={dropdownRef}
                    display={"flex"}
                    flexDirection={"column"}
                    border={"1px solid white"}
                    w={"198px"}
                    // maxH={"275px"}
                    borderRadius={"25px"}
                    bg={"#000000"}
                    position={"absolute"}
                    top={"20px"}
                    right={"20px"}
                    p={"5px 0"}
                  >
                    <Box
                      display={"flex"}
                      justifyContent={"flex-start"}
                      alignItems={"center"}
                      marginBottom={"0.625rem"}
                      gap={"0.62rem"}
                    >
                      <Box
                        sx={theme.accountButton.primary.shape}
                        bg={theme.accountButton.primary.default}
                        color={theme.accountButton.primary.color}
                        css={{
                          width: "49px",
                          height: "49px",
                          padding: "0 5px",
                          border: "none",
                        }}
                      >
                        <Image
                          alt=""
                          style={{
                            borderRadius: "50%",
                            width: "40px",
                            height: "40px",
                            margin: "0",
                          }}
                          src={_U?.userData?.image || defaultProfileImage}
                        />
                      </Box>
                      <Text
                        fontSize={"1rem"}
                        fontWeight={400}
                        color={"rgba(255, 255, 255, 0.60)"}
                        textTransform={"capitalize"}
                        whiteSpace={"nowrap"}
                        overflow={"hidden"}
                        textOverflow={"ellipsis"}
                        width={"140px"}
                      >
                        {!_U?.userData?.firstName && !_U?.userData?.lastName
                          ? `UserName`
                          : `${_U?.userData?.firstName} ${_U?.userData?.lastName}`}
                      </Text>
                    </Box>

                    <Box
                      display={"flex"}
                      flexDirection={"column"}
                      justifyContent={"center"}
                      padding={"0 5px"}
                    >
                      {isUserOrganizer ? (
                        <>
                          <MenuButton
                            onClickFunc={() => navigate("/dashboard")}
                            label={"Enter Event App"}
                          />
                          <MenuButton
                            onClickFunc={() => navigate("/mytickets")}
                            label={"My Tickets"}
                          />
                          <MenuButton
                            onClickFunc={() => navigate("/wishlist")}
                            label={"Wishlist"}
                          />
                          <MenuButton
                            onClickFunc={() => navigate("/settings")}
                            label={"Settings"}
                          />
                          {/* <MenuButton
                            onClickFunc={() => navigate("/myaccount")}
                            label={"Personal Account"}
                          /> */}
                          <MenuButton
                            onClickFunc={() => {
                              setUser((lp) => {
                                return {
                                  ...lp,
                                  token: "",
                                  userData: null,
                                };
                              });
                              setIsVisible(false);
                              localStorage.removeItem("user_d");
                              localStorage.removeItem("x-auth-token");
                              handleLogout();
                              navigate("/");
                            }}
                            className="gordita700"
                            label={"Log Out"}
                          />
                        </>
                      ) : (
                        // isUserAttendee ?
                        <>
                          <MenuButton
                            onClickFunc={() => navigate("/mytickets")}
                            label={"My Tickets"}
                          />
                          <MenuButton
                            onClickFunc={() => navigate("/wishlist")}
                            label={"Wishlist"}
                          />
                          <MenuButton
                            onClickFunc={() => navigate("/settings")}
                            label={"Settings"}
                          />
                          <MenuButton
                            onClickFunc={() => {
                              setUser((lp) => {
                                return {
                                  ...lp,
                                  token: "",
                                  userData: null,
                                };
                              });
                              setIsVisible(false);
                              localStorage.removeItem("user_d");
                              localStorage.removeItem("x-auth-token");
                              handleLogout();
                              navigate("/");
                            }}
                            className="gordita700"
                            label={"Log Out"}
                          />
                        </>
                      )}
                    </Box>
                  </Box>
                )}
              </HStack>
            </HStack>
            {!_U?.token && (
              <Box
                display={isTablet ? "flex" : "none"}
                cursor="pointer"
                onClick={onOpen}
                w={"50px"}
                height={"50px"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Image
                  alt="menu-icon"
                  style={{ width: "24px", height: "24px" }}
                  src={typeMenuIcon}
                />
              </Box>
            )}
          </HStack>
        </Box>
      </Box>

      <Drawer isOpen={isOpen} placement="top" onClose={onClose} size={"full"}>
        <DrawerOverlay>
          <DrawerContent alignItems="center">
            {isMobile && (isUserAttendee || isUserOrganizer) && (
              <DrawerHeader width={"100%"} bg="#000" padding={"1.13rem"}>
                <Box
                  display={"flex"}
                  justifyContent={"flex-start"}
                  alignItems={"center"}
                  marginBottom={"0.625rem"}
                  gap={"0.62rem"}
                >
                  <Box
                    sx={theme.accountButton.primary.shape}
                    bg={theme.accountButton.primary.default}
                    color={theme.accountButton.primary.color}
                    css={{
                      width: "49px",
                      height: "49px",
                      padding: "0 5px",
                      border: "none",
                    }}
                  >
                    <Image
                      alt=""
                      style={{
                        borderRadius: "50%",
                        width: "40px",
                        height: "40px",
                        margin: "0",
                      }}
                      src={_U?.userData?.image || defaultProfileImage}
                    />
                  </Box>
                  <Text
                    fontSize={"1rem"}
                    fontWeight={400}
                    color={"rgba(255, 255, 255, 0.60)"}
                    textTransform={"capitalize"}
                    whiteSpace={"nowrap"}
                    overflow={"hidden"}
                    textOverflow={"ellipsis"}
                    width={"140px"}
                  >
                    {!_U?.userData?.firstName && !_U?.userData?.lastName
                      ? `UserName`
                      : `${_U?.userData?.firstName} ${_U?.userData?.lastName}`}
                  </Text>
                </Box>
              </DrawerHeader>
            )}
            <DrawerCloseButton
              color="white"
              alignSelf="end"
              zIndex={"900"}
              margin={"1rem 0.25rem"}
            />
            <DrawerBody width="100%" bg="black">
              <Menu isOpen={true}>
                <MenuList
                  css={{
                    width: "100vw",
                    height: "100svh",
                    border: "none",
                    backgroundColor: "transparent",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    gap: "50px",
                    padding: "20px",
                  }}
                >
                  {isUserOrganizer ? (
                    <>
                      <MenuItem
                        onClick={() => navigate("/dashboard")}
                        sx={theme.menu.menuItems.selectedShape}
                        color={"#000000"}
                        className="gordita700"
                      >
                        Enter Event App
                      </MenuItem>
                      <MenuItem
                        onClick={() => navigate("/mytickets")}
                        sx={theme.menu.menuItems.shape}
                        className="gordita700"
                      >
                        My Tickets
                      </MenuItem>
                      <MenuItem
                        onClick={() => navigate("/wishlist")}
                        sx={theme.menu.menuItems.shape}
                        className="gordita700"
                      >
                        Wishlist
                      </MenuItem>
                      <MenuItem
                        onClick={() => navigate("/settings")}
                        sx={theme.menu.menuItems.shape}
                        className="gordita700"
                      >
                        Settings
                      </MenuItem>
                      {/* <MenuItem
                        onClick={() => navigate("/myaccount")}
                        sx={theme.menu.menuItems.shape}
                      >
                        Personal Account
                      </MenuItem> */}
                      <MenuItem
                        sx={theme.menu.menuItems.shape}
                        onClick={() => {
                          setUser((lp) => {
                            return {
                              ...lp,
                              token: "",
                              userData: null,
                            };
                          });
                          setIsVisible(false);
                          localStorage.removeItem("user_d");
                          localStorage.removeItem("x-auth-token");
                          handleLogout();
                          navigate("/");
                        }}
                      >
                        Log Out
                      </MenuItem>
                    </>
                  ) : isUserAttendee ? (
                    <>
                      {/* <MenuItem
                        sx={theme.menu.menuItems.shape}
                        css={{ height: "54px" }}
                      >
                        <a
                          className="navLink"
                          href="https://www.partners.portalys.io/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Learn More
                        </a>
                      </MenuItem> */}
                      <MenuItem
                        onClick={() => navigate("/myaccount")}
                        sx={
                          theme.menu.menuItems[
                            window.location.pathname.includes("myaccount")
                              ? "selectedShape"
                              : "shape"
                          ]
                        }
                        css={{ height: "54px" }}
                        className="gordita700"
                      >
                        Account
                      </MenuItem>
                      <MenuItem
                        sx={theme.menu.menuItems.shape}
                        css={{ height: "54px" }}
                        className="gordita700"
                        onClick={() => {
                          setUser((lp) => {
                            return {
                              ...lp,
                              token: "",
                              userData: null,
                            };
                          });
                          setIsVisible(false);
                          localStorage.removeItem("user_d");
                          localStorage.removeItem("x-auth-token");
                          handleLogout();
                          navigate("/");
                        }}
                      >
                        Log Out
                      </MenuItem>
                    </>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100vw",
                        height: "100vh",
                        gap: "3rem", // Add the desired spacing between elements
                        flexDirection: "column", // Set elements to be vertically aligned
                        padding: "20px",
                      }}
                    >
                      <CBtn
                        sx={theme.buttons.primary.shape}
                        bg={theme.buttons.secondary.default}
                        color={theme.buttons.secondary.color}
                        _hover={{ bg: theme.buttons.tercary.hover }}
                        _active={{ bg: theme.buttons.tercary.pressed }}
                        css={{ height: "54px", width: "100%" }}
                        onClick={() => navigate("/contact-us")}
                      >
                        Contact Us
                      </CBtn>

                      <CBtn
                        onClick={() => {
                          setRegistrationModal((lp) => {
                            return {
                              ...lp,
                              openModal: true,
                              modalType: RegistraionModalTypes.LOGIN,
                            };
                          });
                          onClose();
                        }}
                        sx={theme.buttons.secondary.shape}
                        bg={theme.buttons.secondary.default}
                        color={theme.buttons.secondary.color}
                        css={{ width: "100%" }}
                        _hover={{
                          borderColor: theme.buttons.secondary.hoverBorderColor,
                        }}
                        _active={{
                          bg: theme.buttons.secondary.pressed,
                        }}
                        border={theme.buttons.secondary.border}
                      >
                        Log In
                      </CBtn>

                      <CBtn
                        onClick={() => {
                          setRegistrationModal((lp) => {
                            return {
                              ...lp,
                              openModal: true,
                              modalType: RegistraionModalTypes.SIGNUP,
                            };
                          });
                          onClose();
                        }}
                        sx={theme.buttons.mobile.shape}
                        bg={theme.buttons.primary.default}
                        color={theme.buttons.primary.color}
                        _hover={{
                          bg: theme.buttons.primary.hover,
                          boxShadow: theme.buttons.primary.hoverBoxShadow,
                        }}
                        _active={{
                          boxShadow: theme.buttons.primary.activeBoxShadow,
                        }}
                      >
                        Sign Up
                      </CBtn>

                      <CBtn
                        sx={theme.buttons.tercary.shape}
                        bg={theme.buttons.tercary.bgColor}
                        color={theme.buttons.tercary.color}
                        _hover={{ bg: theme.buttons.tercary.hoverBgColor }}
                        _active={{ bg: theme.buttons.tercary.pressedBgColor }}
                        css={{ height: "54px", width: "100%" }}
                        onClick={() => navigate("/create-event")}
                      >
                        Create Your Event!
                      </CBtn>

                      <HStack
                        spacing="65px"
                        css={{ position: "absolute", bottom: "50px" }}
                      >
                        <Link
                          href="https://www.instagram.com/portalys.io/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <InstagramOutlined style={{ fontSize: "25px" }} />
                        </Link>
                        <Link
                          href="https://www.linkedin.com/company/portalys-io/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <LinkedinOutlined style={{ fontSize: "25px" }} />
                        </Link>
                        <Link
                          href="https://twitter.com/PortalysEvents"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <TwitterOutlined style={{ fontSize: "25px" }} />
                        </Link>
                      </HStack>
                    </div>
                  )}
                </MenuList>
              </Menu>
            </DrawerBody>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>

      <Modal
        closeOnOverlayClick={false}
        isOpen={_.openModal}
        onOverlayClick={() => {
          setRegistrationModal((lp) => {
            return { ...lp, openModal: false, userRole: "" };
          });
        }}
        onClose={() => {
          setRegistrationModal((lp) => {
            return { ...lp, openModal: false, userRole: "" };
          });
        }}
        size={isMobile ? "full" : { base: "sm", md: "md", lg: "lg" }} // Set the size conditionally
        isCentered={true}
      >
        <ModalOverlay />
        <ModalContent
          mx={0}
          color="white.100"
          borderRadius="2.5rem"
          boxShadow={
            isMobile
              ? "none"
              : "0px 0px 100px 20px rgba(0, 0, 0, 0.90), -1px -1px 0px 0px rgba(255, 255, 255, 0.20) inset, 1px 1px 0px 0px rgba(255, 255, 255, 0.50) inset"
          }
          bg={"rgba(0, 0, 0, 0.50)"}
          backdropFilter={"blur(150px)"}
        >
          <ModalCloseButton />
          <ModalBody
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            p={isMobile ? 15 : "60px 10px"}
          >
            {renderModal()}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
export default Navbar;
