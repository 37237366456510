import {
  Box,
  Heading,
  HStack,
  Image,
  Text,
  VStack,
  Stack,
  useToast,
  Button,
  Input,
} from "@chakra-ui/react";
import { isEmpty } from "lodash";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import _isEmpty from "lodash/isEmpty";
import Layout from "../../modules/layout/layout";
import cartoon from "../../static/assets/images/cartoon.svg";
import heartfull from "../../static/assets/images/HeartChecked.svg";
import heartempty from "../../static/assets/images/HeartUnchecked.svg";
import EmailIcon from "../../static/assets/images/emailIcon.svg";
import { AiOutlineHeart, AiFillHeart } from "react-icons/ai";
import { Icon } from "@chakra-ui/react";
import "../../styles/portalys/portalys.css";
import { user } from "../../recoil/atoms/user";
import { theme } from "../../styles/theme/base";
import axios from "../../utils/api/axios";
import secrets from "../../secrets";
import { NotificationComponent } from "../../modules/shared/notificationComponent";
import { useMediaQuery } from "../../utils/useMediaQuery";
const moment = require("moment-timezone");

export const formatDate = (dateString, timeZone) => {
  // Parse the input date string in the specified time zone
  const originalDate = moment.tz(dateString, timeZone);
  const day = originalDate.format("D");
  const month = originalDate.format("MMM");
  const weekday = originalDate.format("ddd");

  return `${weekday}, ${day} ${month}`;
};

// Function to format the time to 13h25 format
export const formatTime = (timeString) => {
  const [hours, minutes] = timeString.split(":");
  // Use Moment.js to format the time with AM/PM
  const formattedTime = moment()
    .hours(hours)
    .minutes(minutes)
    .format("hh:mm A");
  return formattedTime;
};

export default function NewsEvents({ init, events }) {
  const isMobile = useMediaQuery("(max-width: 768px)");
  const isTablet = useMediaQuery("(max-width: 1179px)");
  const [email, setEmail] = useState("");
  const [isFocus, setIsFocus] = useState(false);

  const [activeIndex, setActiveIndex] = useState(0);
  const navigate = useNavigate();
  //const [events, setEvents] = useState([]);
  const [_, setUser] = useRecoilState(user);
  const toast = useToast();



  const handleLikeEvent = async (id) => {
    if (_.token) {
      await axios
        .post("/api/likeEvent/" + id, {
          headers: {
            "x-auth-token": _?.token,
          },
        })
        .then((res) => {
          init();
          const data = res.data;
          toast({
            position: "top-right",
            duration: 4000,
            render: ({ onClose }) => (
              <NotificationComponent
                title={
                  data?.message === "Event Liked"
                    ? "Event has been added to your wishlist"
                    : "Event has been removed from your wishlist"
                }
                status={data.status ? "success" : "error"}
                onClose={onClose}
              />
            ),
          });
        })
        .catch((err) => {
          console.log({ err });
        });
    } else {
      toast({
        position: "top-right",
        duration: 4000,
        render: ({ onClose }) => (
          <NotificationComponent
            title={"Please Login"}
            status={"error"}
            onClose={onClose}
          />
        ),
      });
    }
  };
  const subscribe = async () => {
    console.log({ email });
    if (!email) {
      return toast({
        title: "Please enter you email first!",
        status: "warning",
        isClosable: true,
        duration: 4000,
        position: "top-right",
      });
    }
    await axios
      .post( "/api/subscribe", { email })
      .then((res) => {
        const data = res.data;
        toast({
          title: data.message,
          status: data.status ? "success" : "error",
          isClosable: true,
          duration: 4000,
          position: "top-right",
        });
      })
      .catch((err) => {
        console.log({ err });
        toast({
          title: err.message,
          status: "error",
          isClosable: true,
          duration: 4000,
          position: "top-right",
        });
      });
  };

  const shortenToFirstTwoWords = (inputString) => {
    const words = inputString.split(" ");
    return words.slice(0, 2).join(" ");
  };


  return (
    <Box w="100%" display="flex" justifyContent="center" alignItems="center">
      <Box className="gordita400" w="100%">
        <Heading
          color="#FFFFFF"
          fontSize={isMobile ? "2.75rem" : "4.25rem"}
          textAlign="center"
          mb="3.125rem"
          className="gordita700"
          textTransform={"capitalize"}
          letterSpacing={"-0.0425rem"}
        >
          Find The events you love.
        </Heading>
        <Box
          w="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
          rowGap="1.25rem"
          columnGap="1.25rem"
          flexWrap="wrap"
        >
          {events?.map(
            (
              {
                coverPicture: image,
                eventName: heading,
                organizer,
                startDate: sdate,
                startTime: time,
                endTime: etime,
                track,
                categories,
                city,
                country,
                uuid,
                isLiked,
                eventId,
                organizedBy,
                slug,
                timezone,
              },
              index
            ) => {
              const trackObj = JSON.parse(track);
              const formattedDate = formatDate(sdate, timezone);
              const formattedTime = formatTime(time);
              const formattedETime = formatTime(etime);

              return (
                <Box
                  cursor="pointer"
                  onClick={() => navigate("/event/" + slug)}
                  key={index}
                  sx={theme.card.primary.shape}
                  pos={"relative"}
                >
                  <Box
                    width="2.75rem"
                    height="2.75rem"
                    flexShrink="0"
                    borderRadius="6.25rem"
                    border="1px solid rgba(255, 255, 255, 0.05)"
                    bg={"rgba(0, 0, 0, 0.50)"}
                    backdropFilter={"blur(150px)"}
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    pos={"absolute"}
                    right={"1.12rem"}
                    top={"1.12rem"}
                    _hover={{ background: !isLiked && "#000" }}
                  >
                    <Icon
                      as={isLiked ? AiFillHeart : AiOutlineHeart}
                      color="white.100"
                      w="1.5rem"
                      h="1.5rem"
                      cursor="pointer"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleLikeEvent(uuid);
                      }}
                    />
                  </Box>
                  <Image
                    w={"100%"}
                    h="28.0625rem"
                    objectFit={"cover"}
                    flexShrink={0}
                    borderRadius="var(--radius-radius-big, 0.9375rem)"
                    src={image}
                  />

                  <VStack
                    margin="1.25rem 0.62rem"
                    display="block"
                    gap={"0.62rem"}
                  >
                    <Heading
                      color="#FBFAFB"
                      className="gordita700"
                      fontSize="1.4375rem"
                      style={{
                        maxWidth: "100%",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {heading}
                    </Heading>

                    <HStack
                      fontSize={{ base: 14, md: 16, "3xl": 16 }}
                      w="100%"
                      className="gordita400"
                    >
                      <Text className="gordita600">{formattedDate}</Text>
                      <Text> </Text>
                      <Text>{formattedTime + " - " + formattedETime}</Text>
                    </HStack>

                    <HStack
                      fontSize={{ base: 14, md: 16, "3xl": 16 }}
                      w="100%"
                      className="gordita400"
                    >
                      <Text
                        style={{
                          width: "50%",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {organizedBy?.firstName || organizedBy?.lastName
                          ? `${organizedBy?.firstName || ""} ${
                              organizedBy?.lastName || ""
                            }`
                          : "Portalys"}
                      </Text>
                      <Text
                        color="rgba(255, 255, 255, 0.60)"
                        fontSize="1rem"
                        style={{
                          width: "50%",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {city || ""} {city && country ? ", " : ""}{" "}
                        {country || ""}
                      </Text>
                    </HStack>
                  </VStack>
                </Box>
              );
            }
          )}
          <Box
            maxW={
              !isTablet &&
              (events?.length % 3 === 0 ||
                (events?.length % 3 === 1 && events?.length !== 1))
                ? "48.75rem"
                : "23.75rem"
            }
            width={"100%"}
            height={"37.5rem"}
            padding={
              !isTablet &&
              (events?.length % 3 === 0 ||
                (events?.length % 3 === 1 && events?.length !== 1))
                ? "var(--spacing-spacing-none, 12rem) var(--spacing-spacing-none, 8.25rem) var(--spacing-spacing-none, 12rem) var(--spacing-spacing-none, 8.1875rem)"
                : "5.9rem 0.9375rem"
            }
            borderRadius={"1.5625rem"}
            bg="rgba(255, 255, 255, 0.05)"
            display={"flex"}
            flexDir={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            gap={"1.25rem"}
          >
            <Text
              color={"#FFFFFF"}
              fontSize={"2.375rem"}
              className="gordita600"
              lineHeight={"150%"}
              letterSpacing={"-0.02375rem"}
              textTransform={"capitalize"}
              textAlign={"center"}
            >
              More events coming soon!
            </Text>
            <Text
              fontSize={"1.3125rem"}
              className="gordita400"
              lineHeight={"160%"}
              textAlign={"center"}
            >
              Be the first to know about them.
            </Text>
            <Box
              mt={"1.44rem"}
              maxW={isMobile ? "100%" : "26.3125rem"}
              width={"100%"}
            >
              <Stack>
                <HStack
                  maxW={isMobile ? "100%" : "26.3125rem"}
                  width={"100%"}
                  height={"3.125rem"}
                  borderRadius={"6.25rem"}
                  border={"1px solid rgba(255, 255, 255, 0.20)"}
                  borderColor={
                    isFocus ? "#FFFFFF" : "rgba(255, 255, 255, 0.20)"
                  }
                  padding={"0.31rem"}
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  transition={"all 0.2s ease-in-out"}
                  _hover={{
                    borderColor: "#FFFFFF",
                  }}
                >
                  <Box
                    width="2.5rem"
                    height="2.5rem"
                    flexShrink="0"
                    borderRadius="6.25rem"
                    bg={"rgba(255, 255, 255, 0.05)"}
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <Image src={EmailIcon} />
                  </Box>
                  <Input
                    type={"email"}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Give us your email"
                    bg="transparent"
                    padding={"0.42rem"}
                    border={"none"}
                    borderRadius={"6.25rem"}
                    focusBorderColor={"none"}
                    onFocus={() => setIsFocus(true)}
                    onBlur={() => setIsFocus(false)}
                  />
                  {!isTablet &&
                    (events?.length % 3 === 0 ||
                      (events?.length % 3 === 1 && events?.length !== 1)) && (
                      <Button
                        onClick={() => subscribe()}
                        sx={theme.buttons.primary.shape}
                        bg={theme.buttons.primary.default}
                        color={theme.buttons.primary.color}
                        className="gordita700"
                        fontSize="1rem"
                        _hover={{
                          bg: theme.buttons.primary.hover,
                          boxShadow: theme.buttons.primary.hoverBoxShadow,
                        }}
                        _active={{
                          boxShadow: theme.buttons.primary.activeBoxShadow,
                        }}
                        style={{
                          minWidth: "8.125rem",
                          textTransform: "capitalize",
                          height: "2.5rem",
                        }}
                      >
                        Notify me!
                      </Button>
                    )}
                </HStack>
                {!(
                  !isTablet &&
                  (events?.length % 3 === 0 ||
                    (events?.length % 3 === 1 && events?.length !== 1))
                ) && (
                  <Button
                    onClick={() => subscribe()}
                    sx={theme.buttons.primary.shape}
                    bg={theme.buttons.primary.default}
                    color={theme.buttons.primary.color}
                    className="gordita700"
                    fontSize="1rem"
                    _hover={{
                      bg: theme.buttons.primary.hover,
                      boxShadow: theme.buttons.primary.hoverBoxShadow,
                    }}
                    _active={{
                      boxShadow: theme.buttons.primary.activeBoxShadow,
                    }}
                    style={{
                      minWidth: "100%",
                      textTransform: "capitalize",
                    }}
                  >
                    Notify me!
                  </Button>
                )}
              </Stack>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
