import {
  Box,
  Button,
  Divider,
  HStack,
  Text,
  VStack,
  Input,
  chakra,
  Image,
} from "@chakra-ui/react";
import { SignUpOrganizerSchema } from "../../../utils/schema";

import { Formik, Field, Form, ErrorMessage } from "formik";
import {
  RegistraionModalTypes,
  registration,
} from "../../../recoil/atoms/registration";
import { useRecoilState } from "recoil";
import { useNavigate } from "react-router-dom";
import { userRoles } from "../../../utils/constants";
import { useGoogleLogin } from "@react-oauth/google";
import { saveToken } from "../../../utils/handleTokens";
import { user } from "../../../recoil/atoms/user";
import { loginWithGoogle } from "../../../utils/actions/registration";
import { useCookies } from "react-cookie";
import PhoneInput from "react-phone-input-2/";
import "react-phone-input-2/lib/bootstrap.css";
import axios from "../../../utils/api/axios";
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { LightLogo } from "../../../static/assets/images";
import Login from "../Login";
import SignUpVerification from "../SignUpVerification";
import { useMediaQuery } from "../../../utils/useMediaQuery";
import LoginVerification from "../LoginVerification";
import backgroundPicture from "../../../static/assets/images/portalysvisionhero1.png";
import { theme } from "../../../styles/theme/base";

const initialValues = { phoneNumber: "", password: "", confirmPassword: "" };

/**
 * The function handles the sign up process using Google login and saves the user's token and data to
 * local storage and state.
 */
const SignUpOrganizer = ({ displayMobile }) => {
  // Determine if the displayMobile prop is set, otherwise use media query
  const isMobile = displayMobile !== undefined ? displayMobile : useMediaQuery("(max-width: 768px)");

  const [_, setRegistrationModal] = useRecoilState(registration);
  const [u, setUser] = useRecoilState(user);
  const [cookies, setCookie] = useCookies(["x-auth-token"]);
  const location = useLocation();

  const [inputValue, setInputValue] = useState(""); // Initialize with an empty string

  const handleChange = (event) => {
    const newValue = event.target.value;
    setInputValue(newValue); // Update the state with the new value
  };
  const navigate = useNavigate();
  const userRole = userRoles.ORGANIZER;

  const signUpWithGoogle = useGoogleLogin({
    onSuccess: (res) => handleGoogleLogin(res),
    onError: (err) => console.log(err),
    prompt: "select_account",
  });
  const [verificationStatus, setVerificationStatus] = useState("");
  const [verificationCheck, setVerificationCheck] = useState(false);


  const handleGoogleLogin = async (payload) => {
    const user = await loginWithGoogle({ ...payload, userRole });
    saveToken(user.accessToken, "x-auth-token", 60*60, setCookie);
        saveToken(user.accessToken, "accessToken", 60*60, setCookie);
        saveToken(user.refreshToken, "refreshToken", 60*60*24*90, setCookie);
        localStorage.setItem("user_d", JSON.stringify(user.user));
        localStorage.setItem("x-auth-token", user.accessToken);
        setUser((lp) => {
          return {
            ...lp,
            token: user.accessToken,
            userData: user.user,
          };
        });
    if (!user.user.phoneNumber) {
      setRegistrationModal((lp) => {
        return {
          ...lp,
          openModal: true,
          modalType: RegistraionModalTypes.SIGNUP_VERIFICATION,
          userData: {},
          userRole: "",
        };
      });
    } else {
      setRegistrationModal((lp) => {
        return {
          ...lp,
          openModal: false,
          modalType: "",
          userData: {},
          userRole: "",
        };
      });
    }
  };

  const handleSendOTP = async (phoneNumber) => {
    try {
      const response = await axios.post("/api/sendOTP", { phoneNumber });
      if (response.data.success === true) {
        setVerificationStatus(response.data.message);
        console.log("aaaaaaaaachcekoiin");
        setVerificationCheck(true);
        return true;
      } else {
        setVerificationStatus(response.data.message);
        setVerificationCheck(false);
        return false;
      }
    } catch (error) {
      console.error("Error sending OTP:", error);
      setVerificationStatus("Failed to send OTP");
      return false;
    }
  };

  /*const handleClick = () => {
    if ([userRoles.ADMIN, userRoles.ORGANIZER, userRoles.PREMIUM_ORGANIZER].includes(_U.userData?.userRole)) {
      return toast({
        title: "Already a " + _U?.userData?.userRole,
        status: "error",
        isClosable: true,
        duration: 4000,
        position: "top-right",
      });
    } else if (_U.userData?.userRole === userRoles.ATTENDEE) {
      return toast({
        title: "Loged in as ATTENDEE",
        status: "error",
        isClosable: true,
        duration: 4000,
        position: "top-right",
      });
    } else {
      setRegistrationModal((lp) => {
        return {
          ...lp,
          openModal: true,
          modalType: RegistraionModalTypes.SIGNUPORGANIZER,
          userRole: userRoles.ORGANIZER,
        };
      });
      window.scrollTo(0, 0);
    }
  };*/

  /* The code is rendering a sign-up form using Chakra UI components and Formik library. The form
  includes input fields for email, password, and confirm password, and buttons to sign up with email
  or Google. The form also includes validation using Yup schema and error messages for invalid
  input. When the form is submitted, it calls a function to set the registration modal state with
  the user's email and open the password modal for further registration. The code also includes a
  link to the login modal and saves the user's token and data to local storage and state after
  successful registration. */
  return (
    <Box w="100%">
      <Box
        pos="relative"
        left={isMobile ? 0 : "7%"}
        display={"flex"}
        flexDir={"column"}
        justifyContent={"center"}
        alignItems={"flex-start"}
        width={isMobile ? "100%" : "25rem"}
        height={"100vh"}
        p={isMobile ? 20 : 0}
        zIndex={100}
      >
        <HStack flex={4} spacing={0} w="100%" marginTop={'50px'}>
          <Box w="100vh" h="100%">
            <VStack>
              <Text
                className="gordita700"
                fontSize={{ base: 20, md: 24 }}
                textAlign="center"
                mb={isMobile ? 30 : 30}
              >
                Ready to get back control of your tickets?
              </Text>
              <Formik
                initialValues={initialValues}
                validationSchema={SignUpOrganizerSchema}
                onSubmit={async (values) => {
                  try {
                    // Attempt to send OTP
                    const response = await handleSendOTP(
                      "+" + values.phoneNumber
                    );
                    console.log("aaaa", verificationCheck);

                    // Check if OTP sending was successful
                    if (response) {
                      // Proceed with setting the modal
                      setRegistrationModal((lp) => ({
                        ...lp,
                        openModal: true,
                        modalType: RegistraionModalTypes.SIGNUP_VERIFICATION,
                        userRole,
                        userData: {
                          phoneNumber: values.phoneNumber,
                          email: values.email,
                          name: values.name,
                        },
                      }));
                    }
                  } catch (error) {
                    console.error("Error sending OTP:", error);
                    setVerificationStatus("Failed to send OTP");
                    setVerificationCheck(false);
                  }
                }}
              >
                {(formik) => {
                  const { handleSubmit, errors, touched, setFieldValue } =
                    formik;

                  const handlePhoneNumberChange = (value) => {
                    // Check if the value is numeric or empty
                    if (/^\d*$/.test(value) || value === "") {
                      setFieldValue("phoneNumber", value);
                    }
                  };

                  return (
                    <Form onSubmit={handleSubmit} style={{ width: "100%" }}>
                      <VStack alignItems="flex-start" marginBottom={15}>
                        <Text fontWeight={400} fontSize="0.75rem" pl={"1.5rem"}>
                          Email
                        </Text>
                        <Field
                          type="email"
                          name="email"
                          id="email"
                          render={({ field }) => (
                            <Input
                              placeholder="Enter Organizer Email"
                              fontSize={14}
                              {...field}
                              border="1px solid"
                              borderRadius="6.25rem"
                              height="3.375rem"
                              borderColor="#FFFFFF"
                              color="#ffffff"
                              _placeholder={{
                                color: "00000a",
                              }}
                              style={{
                                marginTop: "0.30rem",
                              }}
                              h="48px"
                            />
                          )}
                        />
                        {errors.email && touched.email && (
                          <Text
                            color="red"
                            className="gordita400"
                            fontWeight={400}
                            fontSize={14}
                            pl={"1.5rem"}
                          >
                            {errors.email}
                          </Text>
                        )}
                      </VStack>
                      <VStack alignItems="flex-start" marginBottom={15}>
                        <Text fontWeight={400} fontSize="0.75rem" pl={"1.5rem"}>
                          Name
                        </Text>
                        <Field
                          bg="red"
                          type="name"
                          name="name"
                          id="name"
                          render={({ field }) => (
                            <Input
                              placeholder="Enter Organizer Name"
                              fontSize={14}
                              {...field}
                              border="1px solid"
                              borderRadius="6.25rem"
                              height="3.375rem"
                              borderColor="#FFFFFF"
                              color="#ffffff"
                              _placeholder={{
                                color: "00000a",
                              }}
                              style={{
                                marginTop: "0.30rem",
                              }}
                              h="48px"
                            />
                          )}
                        />
                        {errors.name && touched.name && (
                          <Text
                            color="red"
                            className="gordita400"
                            fontWeight={400}
                            fontSize={14}
                            pl={"1.5rem"}
                          >
                            {errors.name}
                          </Text>
                        )}
                      </VStack>

                      <VStack alignItems="flex-start">
                        <Text fontWeight={400} fontSize="0.75rem" pl={"1.5rem"}>
                          Phone Number
                        </Text>
                        <Field
                          type="phoneNumber"
                          name="phoneNumber"
                          id="phoneNumber"
                          render={({ field }) => (
                            <PhoneInput
                              inputProps={{
                                name: "phoneNumber", // Specify the name for the input field
                                placeholder: "Enter Your Phone Number",
                                style: {
                                  width: "100%",
                                  border: "1px solid #FFFFFF",
                                  borderRadius: "6.25rem",
                                  height: "3.375rem",
                                  fontSize: "14px",
                                  color: "#FFFFFF",
                                  backgroundColor: "transparent",
                                },
                              }}
                              style={{
                                marginTop: "0.30rem",
                              }}
                              dropdownStyle={{ backgroundColor: "#000000" }}
                              country={"us"} // Set your default country
                              value={field.value} // Set the value explicitly
                              onChange={handlePhoneNumberChange} // Handle input changes
                            />
                          )}
                        />

                        {errors.phoneNumber && touched.phoneNumber && (
                          <Text
                            color="red"
                            className="gordita400"
                            fontWeight={400}
                            fontSize={14}
                            pl={"1.5rem"}
                          >
                            {errors.phoneNumber}
                          </Text>
                        )}

                        <p
                          style={{
                            textAlign: "center",
                            fontWeight: 700,
                            color: "red",
                          }}
                        >
                          {verificationStatus}
                        </p>
                      </VStack>

                      <Box mt={24} w="100%">
                        <Button
                          // color="white.100"
                          // bg="primary.100"
                          // type="submit"
                          // className="gordita700"
                          // w="100%"
                          // borderRadius="48px"
                          // h="65px"
                          // _hover={{
                          // bg: "primary.100",
                          // }}
                          type="submit"
                          className="gordita700"
                          sx={theme.buttons.primary.shape}
                          bg={theme.buttons.primary.default}
                          color={theme.buttons.primary.color}
                          css={{
                            width: "100%",
                          }}
                          _hover={{
                            bg: theme.buttons.primary.hover,
                            boxShadow: theme.buttons.primary.hoverBoxShadow,
                          }}
                          _active={{
                            boxShadow: theme.buttons.primary.activeBoxShadow,
                          }}
                        >
                          Receive SMS With The Code
                        </Button>
                      </Box>

                      <Box
                        className="gordita400"
                        fontSize={16}
                        px={{ base: 0, md: 36 }}
                        mt={24}
                        w="100%"
                        textAlign="center"
                      >
                        Have an ORGANIZER account already?
                        <chakra.span
                          color="white"
                          cursor="pointer"
                          fontWeight="700"
                          onClick={() =>
                            setRegistrationModal((lp) => ({
                              ...lp,
                              openModal: true,
                              modalType: RegistraionModalTypes.SIGNUP,
                              userData: {
                              },
                            }))
                          }
                        >
                          {" "}
                          Log In
                        </chakra.span>
                      </Box>
                    </Form>
                  );
                }}
              </Formik>
            </VStack>
          </Box>
        </HStack>
      </Box>
    </Box>
  );
};

export default SignUpOrganizer;
