import React from "react";
import { HStack, Image } from "@chakra-ui/react";

import TextComponent from "./TextComponent";

import { useMediaQuery } from "../../../../utils/useMediaQuery";

function ImageTextComponent({
  imageSrc,
  title,
  subtitle,
  description,
  button,
  displayReverse,
  fullWidth = true,
}) {
  const isMobile = useMediaQuery("(max-width: 768px)");
  const isTablet = useMediaQuery("(max-width: 1024px)");

  return (
    <HStack
      width={"100%"}
      justifyContent={"space-between"}
      flexDir={isTablet ? "column" : displayReverse ? "row-reverse" : "row"}
      gap={"3.12rem"}
    >
      <Image
        src={imageSrc}
        maxW={isTablet ? "100%" : "36.25rem"}
        maxH="36.25rem"
        width={fullWidth && "100%"}
        flexShrink={0}
        borderRadius={isMobile ? "1.5625rem" : "3.125rem"}
      />
      <TextComponent
        title={title}
        subtitle={subtitle}
        description={description}
        button={button}
      />
    </HStack>
  );
}

export default ImageTextComponent;
