import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Divider,
  HStack,
  Text,
  VStack,
  Input,
  chakra,
  Image,
} from "@chakra-ui/react";
import axios from "../../../utils/api/axios";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css"; // Import the styles
import { useRecoilState } from "recoil";
import { user } from "../../../recoil/atoms/user";
import { registration } from "../../../recoil/atoms/registration";
import { useNavigate } from "react-router-dom";
import { theme } from "../../../styles/theme/base";
import { saveToken } from "../../../utils/handleTokens";
import { useCookies } from "react-cookie";

function SignUpVerification() {
  const [verificationStatus, setVerificationStatus] = useState("");
  const [verificationStatus2, setVerificationStatus2] = useState("");
  const [enteredOTP, setEnteredOTP] = useState("");
  const [_, setUser] = useRecoilState(user);
  const [s, setRegistrationModal] = useRecoilState(registration);
  const navigate = useNavigate();
  const [cookies, setCookie] = useCookies(["x-auth-token"]);

  const shouldreturn = location.pathname.includes("/completeregistration");
  const forOrganizers=location.pathname.includes("/create-event");

  const handleSendOTP = async (phoneNumber) => {
    try {
      const response = await axios.post("/api/sendOTP", { phoneNumber });
      if (response.data.success === true) {
        setVerificationStatus(response.data.message);
      } else {
        setVerificationStatus(response.data.message);
      }
    } catch (error) {
      console.error("Error sending OTP:", error);
      setVerificationStatus("Failed to send OTP");
    }
  };

  const authenticate = async (
    phoneNumber,
    enteredOTP,
    userRole,
    name,
    email,
    isCompletingReg,
  ) => {
    try {
      console.log("checkpoint");
      let url;
      if (forOrganizers){
        url="/api/authenticateOrganizer";
      } else {
        url="/api/authenticate";

      }
      const response = await axios.post(url, {
        phoneNumber,
        otp: enteredOTP,
        userRole,
        name,
        email,
        isCompletingReg
      });
      if (response.data.success === true) {
        localStorage.removeItem("user_d");
        localStorage.removeItem("x-auth-token");
        setVerificationStatus2(response.data.message);
        const user = response.data;
        saveToken(user.accessToken, "x-auth-token", 60*60, setCookie);
        saveToken(user.accessToken, "accessToken", 60*60, setCookie);
        saveToken(user.refreshToken, "refreshToken", 60*60*24*90, setCookie);
        localStorage.setItem("user_d", JSON.stringify(user.user));
        localStorage.setItem("x-auth-token", user.accessToken);
        setUser((lp) => {
          return {
            ...lp,
            token: user.accessToken,
            userData: user.user,
          };
        });

        setRegistrationModal((lp) => {
          return {
            ...lp,
            openModal: false,
            modalType: "",
            userData: {},
            userRole: "",
          };
        });

        if (shouldreturn) {
          navigate("/dashboard");
        }
      } else {
        setVerificationStatus2(response.data.message);
      }
    } catch (error) {
      console.error("Error verifying OTP:", error);
      setVerificationStatus2("Failed to verify OTP");
    }
  };
  const [inputValue, setInputValue] = useState(""); // Initialize state with an empty string

  const handleInputChange = (event) => {
    setInputValue(event.target.value); // Update inputValue state when the input value changes
  };

  return (
    <Box w="100%">
      <VStack gap={10} width="100%">
        <Text className="gordita700" fontSize="26px">
          Great!
        </Text>

        <Text
          px={{ base: 0, md: 36 }}
          textAlign={"center"}
          className="gordita700"
          fontSize="20px"
        >
          Check your SMS inbox for the code sent to verify your identity.
        </Text>
        <VStack width={"100%"} px={{ base: 0, md: 36 }} alignItems="flex-start">
          <Text fontWeight={400} fontSize="0.75rem" pl={"1.5rem"}>
            Code
          </Text>
          <Input
            fontSize={14}
            border="1px solid"
            borderRadius="6.25rem"
            height="3.375rem"
            borderColor="rgba(255, 255, 255, 0.20)"
            // type="text"
            color="#FFFFFF"
            focusBorderColor="#FFFFFF"
            _hover={{
              borderColor: "#FFFFFF !important",
            }}
            _placeholder={{
              color: "rgba(255, 255, 255, 0.40)",
            }}
            style={{
              marginTop: "0.30rem",
            }}
            h="48px"
            value={inputValue} // Bind the input value to the state
            onChange={handleInputChange} // Handle input changes
          />
          <p style={{ textAlign: "center", fontWeight: 700, color: "red" }}>
            {verificationStatus2}
          </p>
        </VStack>

        <Box px={{ base: 0, md: 36 }} mt={24} w="100%">
          <Button
            type="submit"
            className="gordita700"
            borderRadius="48px"
            sx={theme.buttons.primary.shape}
            bg={theme.buttons.primary.default}
            color={theme.buttons.primary.color}
            _hover={{
              bg: theme.buttons.primary.hover,
              boxShadow: theme.buttons.primary.hoverBoxShadow,
            }}
            _active={{
              boxShadow: theme.buttons.primary.activeBoxShadow,
            }}
            style={{
              width: "100%",
            }}
            onClick={() =>
              authenticate(
                "+" + s.userData.phoneNumber,
                inputValue,
                s.userRole,
                s.userData.name,
                s.userData.email,
                s.userData.isCompletingReg,
              )
            }
          >
            Enter Portalys
          </Button>
        </Box>

        <Box
          className="gordita400"
          fontSize={16}
          px={{ base: 0, md: 36 }}
          mt={24}
          w="100%"
          textAlign="center"
        >
          Didn't receive the code?
          <chakra.span
            color="white"
            cursor="pointer"
            fontWeight="700"
            onClick={() => handleSendOTP("+" + s.userData.phoneNumber)}
          >
            {" "}
            Resend
          </chakra.span>
        </Box>
      </VStack>
    </Box>
  );
}

export default SignUpVerification;
