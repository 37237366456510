import React, { useState, useRef } from "react";
import {
  Box,
  Button,
  HStack,
  Text,
  VStack,
  Image,
  useToast,
  Icon,
  Input,
  Flex,
  Tooltip,
  IconButton,
} from "@chakra-ui/react";
import { theme } from "../../../../styles/theme/base";
import deleteIcon from "../../../../static/assets/images/deleteIcon.svg";
import editIcon from "../../../../static/assets/images/editIcon.svg";
import videocamIcon from "../../../../static/assets/images/videocamIcon.svg";
import secrets from "../../../../secrets.json";
import axios from "../../../../utils/api/axios";
import { upload2 } from "../../../../static/assets/images/dashboard/sidebar";
// import { DeleteIcon, InfoOutlineIcon } from "@chakra-ui/icons";
import { exportSvg } from "../../../../static/assets/images/dashboard/sidebar";
import defaultCoverImage from "../../../../static/assets/images/defaultCoverImage.png";
import defaultCoverImage1 from "../../../../static/assets/images/defaultCoverImage1.png";

function Trailer(props) {
  const { handleBlur, values, setFieldValue } = props;

  const [focused, setFocused] = useState(false);

  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState(null);
  const [dragging, setDragging] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const dropRef = useRef(null);

  const uploadTrailer = async (video) => {
    const fd = new FormData();
    fd.append("video", video);
    fd.append("type", "trailer");
    setLoading(true);
    await axios
      .post("/api/uploadVideo", fd,{
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        if (res.data.status) {
          if (res.data.link) {
            setFieldValue("trailer", res.data.link);
          }
        } else {
          toast({
            title: "Trailer Uploading Err",
            status: "error",
            isClosable: true,
            duration: 4000,
            position: "top-right",
          });
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        toast({
          title: "Trailer Uploading Err",
          status: "error",
          isClosable: true,
          duration: 4000,
          position: "top-right",
        });
      });
  };

  return (
    <VStack width={"100%"} alignItems={"flex-start"}>
      <Text
        fontSize={"1.25rem"}
        className="gordita700"
        textTransform={"capitalize"}
      >
        Trailer
      </Text>

      <HStack gap={"1.25rem"}>
        <Box
          width={values?.trailer ? "100%" : "39.625rem"}
          height={values?.trailer ? "100%" : "3.4375rem"}
          borderRadius={values?.trailer ? "8px" : "6.25rem"}
          w="100%"
          display="flex"
          justifyContent="center"
          border={"1px solid rgba(255, 255, 255, 0.20)"}
          borderColor={focused ? "#FFFFFF" : "rgba(255, 255, 255, 0.20)"}
          padding={"0.31rem"}
          alignItems={"center"}
          gap={"0.63rem"}
          _hover={{
            borderColor: "#FFFFFF",
          }}
          cursor={"pointer"}
        >
          <Box
            width={"2.8125rem"}
            height={"2.8125rem"}
            borderRadius={"50%"}
            backgroundColor={"rgba(255, 255, 255, 0.05)"}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <img
              src={videocamIcon}
              alt={"spotify-icon"}
              style={{ width: "1.5rem", height: "1.5rem" }}
            />
          </Box>
          <Box width={"80%"}>
            <input
              style={{ display: "none" }}
              id="trailerImage"
              type="file"
              accept=".mp4,.avi,.flv,.mkv,.webm"
              onChange={async (e) => {
                if (e.target.files[0].size > 20000000) {
                  toast({
                    title: "File not uploaded",
                    description: "File size must be less than 20mb",
                    status: "error",
                    isClosable: true,
                    duration: 4000,
                    position: "bottom",
                  });
                  this.value = "";
                } else {
                  await uploadTrailer(e.target.files[0]);
                }
              }}
            ></input>
            {values?.trailer ? (
              <video
                src={values.trailer}
                width="100%"
                controls
                autoPlay={false}
                style={{
                  width: "100%",
                  aspectRatio: "16/9",
                  marginTop: "25px",
                }}
              />
            ) : (
              <label htmlFor="trailerImage">
                <Box
                  cursor="pointer"
                  width="100%"
                  display="flex"
                  justifyContent="flex-start"
                  alignItems="center"
                  h="100%"
                  fontSize={"1rem"}
                  className="gordita700"
                  lineHeight={"150%"}
                  letterSpacing={"-0.01rem"}
                >
                  Upload a trailer
                </Box>
              </label>
            )}
          </Box>
        </Box>
        {values?.trailer && (
          <Box
            display={"flex"}
            justifyContent={"flex-end"}
            alignItems={"center"}
            gap={"0.94rem"}
          >
            <Button
              sx={theme.buttons.secondary.shape}
              bg={theme.buttons.secondary.default}
              color={theme.buttons.secondary.color}
              _hover={{
                borderColor: theme.buttons.secondary.hoverBorderColor,
              }}
              _active={{
                bg: theme.buttons.secondary.pressed,
              }}
              style={{
                width: "3.4375rem",
                height: "3.4375rem",
                borderRadius: "50%",
              }}
              border={"1px solid #FFFFFF33"}
              onClick={() => setFieldValue("trailer", "")}
            >
              <img
                src={deleteIcon}
                alt={"delete-icon"}
                style={{ width: "44px", height: "44px", fontSize: "40px" }}
              />
            </Button>
            <label htmlFor="trailerImage">
              <Box
                sx={theme.buttons.secondary.shape}
                bg={theme.buttons.secondary.default}
                color={theme.buttons.secondary.color}
                _hover={{
                  borderColor: theme.buttons.secondary.hoverBorderColor,
                }}
                _active={{
                  bg: theme.buttons.secondary.pressed,
                }}
                style={{
                  width: "3.4375rem",
                  height: "3.4375rem",
                  borderRadius: "50%",
                  cursor: "pointer",
                }}
                border={"1px solid #FFFFFF33"}
              >
                <img
                  src={editIcon}
                  alt={"delete-icon"}
                  style={{
                    width: "1.5rem",
                    height: "1.5rem",
                    fontSize: "40px",
                  }}
                />
              </Box>
            </label>
          </Box>
        )}
      </HStack>
    </VStack>
  );
}

export default Trailer;
