import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  HStack,
  Image,
  Text,
  VStack,
  Heading,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Modal,
  useDisclosure,
  Flex,
  Icon

} from "@chakra-ui/react";
import { useRecoilState } from "recoil";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import Layout from "../../modules/layout/layout";
import { useMediaQuery } from "../../utils/useMediaQuery";
import { user } from "../../recoil/atoms/user";
import { formatDate, formatTime } from "../portalys/newsevents";
import { theme } from "../../styles/theme/base";
import axios from "../../utils/api/axios";
import TicketModal from "./model";
import { FaApple,FaGoogleWallet } from 'react-icons/fa';
import appleWallet from "../../static/assets/images/US-UK_Add_to_Apple_Wallet_RGB_101421.svg";
import googleWallet from "../../static/assets/images/enUS_add_to_google_wallet_add-wallet-badge.svg";


function MyTickets(props) {
  const navigate = useNavigate();

  const isTablet = useMediaQuery("(max-width: 1192px)");
  const isMobile = useMediaQuery("(max-width: 768px)");
  const [showDisclaimer, setShowDisclaimer] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [_, setUser] = useRecoilState(user);
  const [tickets, setTickets] = useState([]);
  const [selectedTicket, setSelectedTicket] = useState(null);
  const [isTicketsModalOpen, setIsTicketsModalOpen] = useState(false);

  const currentDate = moment().format("YYYY-MM-DD");
  const fetchNFTs = async () => {
    if (_?.token) {
      try {
        const response = await axios.get("/api/getNFTs", {
          headers: {
            "x-auth-token": _?.token,
          },
        });
        setTickets(response.data.NFTs);
      } catch (error) {
        console.error("Error fetching NFTs:", error);
      }
    }
  };

  useEffect(() => {
    // Function to check if the URL contains the payment_intent parameter
    const hasPaymentIntentParam = () => {
      const urlParams = new URLSearchParams(window.location.search);
      return urlParams.has('payment_intent');
    };

    const hasRSVPparam = () => {
      const urlParams = new URLSearchParams(window.location.search);
      return urlParams.has('rsvp');
    };


    // Check if the payment_intent parameter is present in the URL
    if (hasPaymentIntentParam()||hasRSVPparam()) {
      setShowDisclaimer(true);
      onOpen(); // Open the modal
    }
  }, [onOpen]);
  const handleCloseDisclaimer = () => {
    // Clear the payment_intent parameter from the URL
    navigate('/mytickets');

    setShowDisclaimer(false);
    onClose(); // Close the modal
    fetchNFTs();
  };
  useEffect(() => {

    fetchNFTs();
  }, [_?.token]);
  const addToApple = async () => {
    if (_?.token) {
      try {
        const walletAddress=_?.userData.walletAddress;
         const response= await axios.get("/wallet/download/"+walletAddress, {
          headers: {
            "x-auth-token": _?.token,
          },
          responseType: 'arraybuffer',
        });
        console.log('aaa',response);
        const blob = new Blob([response.data], { type: 'application/vnd.apple.pkpass' });

    // Create a URL object from the blob
    const url = window.URL.createObjectURL(blob);

    // Create an anchor element to trigger the download
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'portalys.pkpass'); // Set the filename here
    document.body.appendChild(link);

    // Initiate the download
    link.click();

    // Clean up
    window.URL.revokeObjectURL(url);
    document.body.removeChild(link);
       
      } catch (error) {
        console.error("Error downloading pass:", error);
      }
    }
  };

  const addToGoogle = async () => {
    if (_?.token) {
      try {
        const walletAddress=_?.userData.walletAddress;
         const response= await axios.get("/wallet/getGooglePass/"+walletAddress, {
          headers: {
            "x-auth-token": _?.token,
          },
        });
        const passUrl = response.data.passUrl;

        // Initiate the download by opening the URL in a new tab
        window.open(passUrl, "_blank");
       
      } catch (error) {
        console.error("Error downloading pass:", error);
      }
    }
  };

  // Handler to open the modal and set the selected ticket
  const openModal = (ticket) => {
    setSelectedTicket(ticket);
    setIsTicketsModalOpen(true);
  };

  // Handler to close the modal and reset the selected ticket
  const closeModal = () => {
    setSelectedTicket(null);
    setIsTicketsModalOpen(false);
  };

  return (
    <Layout {...props}>
      {selectedTicket && isTicketsModalOpen && (
        <TicketModal
          isOpen={isTicketsModalOpen}
          tickets={selectedTicket} // Pass the selected ticket data to the modal component
          closeModal={closeModal} // Pass the closeModal function to the modal component
        />
      )}

<Modal isOpen={isOpen} onClose={handleCloseDisclaimer} isCentered size="xl">
      <ModalOverlay />
      <ModalContent
        zIndex="999"
        maxW={isMobile ? "90%" : "42.4375rem"}
        maxHeight={"90%"}
        borderRadius={ "2.125rem"}
        boxShadow={"0px 0px 100px 150px rgba(0, 0, 0, 0.30)"}
        backgroundColor={isMobile ? "#000000" : "rgba(0, 0, 0, 0.50)"}
        border={"1px solid rgba(255, 255, 255, 0.20)"}
        backdropFilter={"blur(75px)"}
        padding={ "1rem 0.6rem"}
        style={{
          width: "42.4375rem !important",
        }}
      >
        <ModalHeader  fontSize={isMobile ? "1.3125rem" : "1.5625rem"}
            className="gordita700"
            padding={"1.25rem"}>Ticket(s) Purchased Successfully!</ModalHeader>
        <ModalCloseButton color="white" />
        <ModalBody marginTop={"1rem"}>
          <p>
            You will receive a confirmation email at {_?.userData?.email || 'your address'}, and your ticket will appear on this page.
          </p>
          <br />
          <p>Note: It may take up to 3 minutes to receive your tickets since we're using blockchain technology to secure all transactions!</p>
        </ModalBody>
        <ModalFooter>
        <Button
                      variant="ghost"
                      onClick={handleCloseDisclaimer}
                      sx={theme.buttons.secondary.shape}
                      bg={theme.buttons.secondary.bgColor}
                      color={theme.buttons.secondary.color}
                      css={{
                        height: "43px",
                        width: "fit-content",
                      }}
                      _hover={{
                        borderColor: theme.buttons.secondary.hoverBorderColor,
                      }}
                      _active={{
                        bg: theme.buttons.secondary.pressed,
                      }}
                      border={theme.buttons.secondary.border}
                    >
                      Close
                    </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
      <Box
        padding={isTablet ? "0 1.25rem" : "0"}
        margin={isTablet ? "" : "0 0 5.31rem 5.31rem"}
      >
        <Text
          textAlign={"center"}
          margin={isMobile ? "2.19rem auto" : "5.31rem auto 4.37rem"}
          fontSize={isMobile ? "1.75rem" : "2.375rem"}
          className="gordita600"
          lineHeight={"150%"}
          letterSpacing={"-0.02375rem"}
          textTransform={"capitalize"}
        >
          My Tickets
        </Text>
        {isMobile &&(


<Flex marginTop={"10px"} justify="center" direction="row" align="center" gap={"10px"} marginBottom={'20px'}>
<Box
      onClick={addToApple}
    >
      <Image
        src={appleWallet}
        style={{
          width: "15.75rem",
          //height: "1.5rem",
          minHeight:"48px"
        }}
      />
    </Box>
<Box
      onClick={addToGoogle}
    >
      <Image
        src={googleWallet}
        style={{
          width: "19.75rem",
          //height: "1.5rem",
          minHeight:"48px"
        }}
      />
    </Box>
    </Flex>
    )}
        <Box>
          <Text
            className="gordita600"
            fontSize={isMobile ? "1.4375rem" : "1.8125rem"}
            lineHeight={"150%"}
            letterSpacing={"-0.01813rem"}
            textTransform={"capitalize"}
            marginBottom={isMobile ? "0.94rem" : "1.87rem"}
          >
            Coming events
          </Text>

          {tickets?.filter((ticket) => ticket.event.endDate >=currentDate)
            .length ? (
            <Box
              w="100%"
              display="flex"
              justifyContent={isMobile ? "center" : "flex-start"}
              alignItems="center"
              overflow={isMobile ? "unset" : "scroll"}
              gap={"0.62rem"}
              rowGap={isMobile ? "0.62rem" : "1.25rem"}
              columnGap={"1.25rem"}
              flexWrap={isMobile ? "wrap" : "unset"}
            >
              {tickets
                ?.filter((ticket) => ticket.event.endDate >= currentDate)
                .map((ticket, index) => {
                let {
                  coverPicture: image,
                  eventName: heading,
                  organizer,
                  startDate: sdate,
                  startTime: time,
                  endTime: etime,
                  track,
                  categories,
                  city,
                  country,
                  uuid,
                  isLiked,
                  eventId,
                  organizedBy,
                  slug,
                  timezone,
                } = ticket.event;
                const formattedDate = formatDate(sdate, timezone);
                const formattedTime = formatTime(time);
                const formattedETime = formatTime(etime);
                return (
                  <Box
                    // onClick={() => navigate("/event/" + slug)}
                    sx={theme.card.primary.shape}
                    key={ticket?.event?.idEvent}
                    onClick={() => openModal(ticket)}
                    css={{
                      height: isMobile ? "7rem" : "31.938rem",
                      display: isMobile ? "flex" : "unset",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      gap: "0.62rem",
                      padding: isMobile
                        ? "0.31rem 0.94rem 0.31rem 0.31rem"
                        : "0.62rem 0.62rem 1.56rem",
                      cursor: "pointer",
                      borderRadius: isMobile ? "0.9375rem" : "1.5625rem",
                    }}
                  >
                    <Box
                      display={"flex"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      pos={"relative"}
                      flex={"0 0 auto"}
                    >
                      <Image
                        w={isMobile ? "6.375rem" : "100%"}
                        h={isMobile ? "6.375rem" : "22.5rem"}
                        objectFit={"cover"}
                        borderRadius="var(--radius-radius-big, 0.9375rem)"
                        src={image}
                      />
                    </Box>

                    <VStack
                      margin="1.25rem 0.62rem"
                      display="block"
                      gap={"0.62rem"}
                      overflow={"hidden"}
                    >
                      <Heading
                        color="#FBFAFB"
                        className="gordita700"
                        fontSize="1.4375rem"
                        textTransform={"capitalize"}
                        style={{
                          maxWidth: "22.5rem",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {heading}
                      </Heading>

                      <HStack
                        fontSize={{ base: 14, md: 16, "3xl": 16 }}
                        w="100%"
                        className="gordita400"
                      >
                        <Text className="gordita600" whiteSpace={"nowrap"}>
                          {formattedDate}
                        </Text>
                        <Text whiteSpace={"nowrap"}>
                          {formattedTime + " - " + formattedETime}
                        </Text>
                      </HStack>

                      <HStack
                        fontSize={{ base: 14, md: 16, "3xl": 16 }}
                        w="100%"
                        className="gordita400"
                      >
                        <Text
                          style={{
                            width: "50%",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {organizedBy?.firstName || organizedBy?.lastName
                            ? `${organizedBy?.firstName || ""} ${
                                organizedBy?.lastName || ""
                              }`
                            : "Portalys"}
                        </Text>
                        <Text
                          color="rgba(255, 255, 255, 0.60)"
                          fontSize="1rem"
                          style={{
                            width: "50%",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {city || ""} {city && country ? ", " : ""}{" "}
                          {country || ""}
                        </Text>
                      </HStack>
                    </VStack>
                  </Box>
                );
              })}
            </Box>
          ) : (
            <>
              <Text marginTop="4rem" fontSize="1.25rem" fontWeight="700">
                You have no tickets yet
              </Text>
              <Button
                marginTop="1.56rem"
                sx={theme.buttons.secondary.shape}
                bg={theme.buttons.secondary.bgColor}
                color={theme.buttons.secondary.color}
                border={theme.buttons.secondary.border}
                _hover={{
                  borderColor: theme.buttons.secondary.hoverBorderColor,
                }}
                _active={{
                  bg: theme.buttons.secondary.pressed,
                }}
                style={{
                  width: "15.75rem",
                }}
                onClick={() => navigate("/")}
              >
                Find Events!
              </Button>
            </>
          )}
        </Box>
        <Box>
          <Text
            className="gordita600"
            fontSize={isMobile ? "1.4375rem" : "1.8125rem"}
            lineHeight={"150%"}
            letterSpacing={"-0.01813rem"}
            textTransform={"capitalize"}
            marginTop={isMobile ? "2.81rem" : "6.25rem"}
            marginBottom={isMobile ? "0.94rem" : "1.87rem"}
          >
            Past Events
          </Text>

          {tickets?.filter((ticket) => ticket.event.endDate < currentDate)
            .length ? (
            <Box
              w="100%"
              display="flex"
              justifyContent={isMobile ? "center" : "flex-start"}
              alignItems="center"
              overflow={isMobile ? "unset" : "scroll"}
              gap={"0.62rem"}
              rowGap={isMobile ? "0.62rem" : "1.25rem"}
              columnGap={"1.25rem"}
              flexWrap={isMobile ? "wrap" : "unset"}
            >
              {tickets
                ?.filter((ticket) => ticket.event.endDate < currentDate)
                .map((ticket, index) => {
                  let {
                    coverPicture: image,
                    eventName: heading,
                    organizer,
                    startDate: sdate,
                    startTime: time,
                    endTime: etime,
                    track,
                    categories,
                    city,
                    country,
                    uuid,
                    isLiked,
                    eventId,
                    organizedBy,
                    slug,
                    timezone,
                  } = ticket.event;
                  const formattedDate = formatDate(sdate, timezone);
                  const formattedTime = formatTime(time);
                  const formattedETime = formatTime(etime);
                  return (
                    <Box
                      //   onClick={() => navigate("/event/" + slug)}
                      key={index}
                      sx={theme.card.primary.shape}
                      css={{
                        height: isMobile ? "7rem" : "31.938rem",
                        display: isMobile ? "flex" : "unset",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        gap: "0.62rem",
                        padding: isMobile
                          ? "0.31rem 0.94rem 0.31rem 0.31rem"
                          : "0.62rem 0.62rem 1.56rem",
                        cursor: "pointer",
                      }}
                    >
                      <Box
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        pos={"relative"}
                        flex={"0 0 auto"}
                      >
                        <Image
                          w={isMobile ? "6.375rem" : "100%"}
                          h={isMobile ? "6.375rem" : "22.5rem"}
                          objectFit={"cover"}
                          borderRadius="var(--radius-radius-big, 0.9375rem)"
                          src={image}
                        />
                      </Box>

                      <VStack
                        margin="1.25rem 0.62rem"
                        display="block"
                        gap={"0.62rem"}
                        overflow={"hidden"}
                      >
                        <Heading
                          color="#FBFAFB"
                          className="gordita700"
                          fontSize="1.4375rem"
                          textTransform={"capitalize"}
                          style={{
                            maxWidth: "22.5rem",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {heading}
                        </Heading>

                        <HStack
                          fontSize={{ base: 14, md: 16, "3xl": 16 }}
                          w="100%"
                          className="gordita400"
                        >
                          <Text className="gordita600" whiteSpace={"nowrap"}>
                            {formattedDate}
                          </Text>
                          <Text whiteSpace={"nowrap"}>
                            {formattedTime + " - " + formattedETime}
                          </Text>
                        </HStack>

                        <HStack
                          fontSize={{ base: 14, md: 16, "3xl": 16 }}
                          w="100%"
                          className="gordita400"
                        >
                          <Text
                            style={{
                              width: "50%",
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {organizedBy?.firstName || organizedBy?.lastName
                              ? `${organizedBy?.firstName || ""} ${
                                  organizedBy?.lastName || ""
                                }`
                              : "Portalys"}
                          </Text>
                          <Text
                            color="rgba(255, 255, 255, 0.60)"
                            fontSize="1rem"
                            style={{
                              width: "50%",
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {city || ""} {city && country ? ", " : ""}{" "}
                            {country || ""}
                          </Text>
                        </HStack>
                      </VStack>
                    </Box>
                  );
                })}
            </Box>
          ) : (
            <>
              <Text marginTop="4rem" fontSize="1.25rem" fontWeight="700">
                You have not attend an event yet
              </Text>
              <Button
                marginTop="1.56rem"
                sx={theme.buttons.secondary.shape}
                bg={theme.buttons.secondary.bgColor}
                color={theme.buttons.secondary.color}
                border={theme.buttons.secondary.border}
                _hover={{
                  borderColor: theme.buttons.secondary.hoverBorderColor,
                }}
                _active={{
                  bg: theme.buttons.secondary.pressed,
                }}
                style={{
                  width: "15.75rem",
                }}
                onClick={() => navigate("/")}
              >
                Find Events!
              </Button>
            </>
          )}
        </Box>
      </Box>
    </Layout>
  );
}

export default MyTickets;
