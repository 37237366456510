import React from "react";
import { Box, Heading, Button, VStack,   useToast,} from "@chakra-ui/react";

import { useMediaQuery } from "../../../../utils/useMediaQuery";
import ImageTextComponent from "./ImageTextComponent";
import { theme } from "../../../../styles/theme/base";
import marketplace1 from "../../../../static/assets/images/marketplace1.png";
import marketplace4 from "../../../../static/assets/images/marketplace4.png";
import { RegistraionModalTypes,registration } from "../../../../recoil/atoms/registration";
import { useRecoilState } from "recoil";
import {user} from "../../../../recoil/atoms/user";
import { NotificationComponent } from "../../../shared/notificationComponent";

function EventsOverview() {
  const isMobile = useMediaQuery("(max-width: 768px)");
  const isTablet = useMediaQuery("(max-width: 1024px)");
  const [_, setRegistrationModal] = useRecoilState(registration);
  const [_U, setUser] = useRecoilState(user);
  const toast = useToast();


  return (
    <Box w="100%">
      <Heading
        color="#FFFFFF"
        fontSize={isMobile ? "2.0625rem" : "3.125rem"}
        textAlign="center"
        mb={isMobile ? "4.69rem" : "7.19rem"}
        className="gordita700"
        textTransform={"capitalize"}
        letterSpacing={"-0.02063rem"}
      >
        The best way to enjoy events.
      </Heading>
      <Box
        display={"flex"}
        flexDir={"column"}
        gap={isTablet ? "5.31rem" : "16.16rem"}
      >
        <ImageTextComponent
          imageSrc={marketplace1}
          title={"Get your tickets with peace of mind."}
          subtitle={"100% free of frauds."}
          description={
            "Your tickets cannot be stolen, duplicated, or hacked. We use blockchain for the good, making it easy for everyone."
          }
          button={
            <Button
              // onClick={handleClick}
              sx={theme.buttons.primary.shape}
              bg={theme.buttons.primary.default}
              color={theme.buttons.primary.color}
              className="gordita700"
              fontSize="1rem"
              _hover={{
                bg: theme.buttons.primary.hover,
                boxShadow: theme.buttons.primary.hoverBoxShadow,
              }}
              _active={{
                boxShadow: theme.buttons.primary.activeBoxShadow,
              }}
              style={{
                minWidth: isTablet ? "100%" : "fit-content",
                padding: "0 1.56rem",
                textTransform: "capitalize",
              }}
              onClick={() => {
                if(_U.userData?.userId)
                {
                  toast({
                    position: "top-right",
                    duration: 4000,
                    render: ({ onClose }) => (
                      <NotificationComponent
                        title={"Already Logged In"}
                        status={"success"}
                        onClose={onClose}
                      />
                    ),
                  });


                }else{
                setRegistrationModal((lp) => {
                  return {
                    ...lp,
                    openModal: true,
                    modalType: RegistraionModalTypes.SIGNUP,
                  };
                });
              }
              }}
            >
              Create your account
            </Button>
          }
        />

        <ImageTextComponent
          displayReverse={true}
          fullWidth={false}
          imageSrc={marketplace4}
          title={"No need to search for lost emails anymore."}
          subtitle={"Access to any event effortlessly."}
          description={
            "Log In and access your QR code on the menu. They change every 30 seconds, so no need to take a screenshot. No connection? No problem, say your name and present an ID at the door and you're all set."
          }
          button={
            <Button
              // onClick={handleClick}
              sx={theme.buttons.primary.shape}
              bg={theme.buttons.primary.default}
              color={theme.buttons.primary.color}
              className="gordita700"
              fontSize="1rem"
              _hover={{
                bg: theme.buttons.primary.hover,
                boxShadow: theme.buttons.primary.hoverBoxShadow,
              }}
              _active={{
                boxShadow: theme.buttons.primary.activeBoxShadow,
              }}
              style={{
                minWidth: isTablet ? "100%" : "fit-content",
                padding: "0 1.56rem",
                textTransform: "capitalize",
              }}
              onClick={() => {
                if(_U.userData?.userId)
                {
                  toast({
                    position: "top-right",
                    duration: 4000,
                    render: ({ onClose }) => (
                      <NotificationComponent
                        title={"Already Logged In"}
                        status={"success"}
                        onClose={onClose}
                      />
                    ),
                  });


                }else{
                setRegistrationModal((lp) => {
                  return {
                    ...lp,
                    openModal: true,
                    modalType: RegistraionModalTypes.SIGNUP,
                  };
                });
              }
              }}
            >
              Create your account
            </Button>
          }
        />
      </Box>
    </Box>
  );
}

export default EventsOverview;
