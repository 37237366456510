import React, { useState, useRef } from "react";
import {
  Box,
  Button,
  HStack,
  Text,
  VStack,
  Image,
  useToast,
  Icon,
  Input,
  Flex,
  Tooltip,
  IconButton,
} from "@chakra-ui/react";
import { theme } from "../../../../styles/theme/base";
import deleteIcon from "../../../../static/assets/images/deleteIcon.svg";
import editIcon from "../../../../static/assets/images/editIcon.svg";
import videocamIcon from "../../../../static/assets/images/videocamIcon.svg";
import secrets from "../../../../secrets.json";
import { upload2 } from "../../../../static/assets/images/dashboard/sidebar";
// import { DeleteIcon, InfoOutlineIcon } from "@chakra-ui/icons";
import { exportSvg } from "../../../../static/assets/images/dashboard/sidebar";
import defaultCoverImage from "../../../../static/assets/images/defaultCoverImage.png";
import defaultCoverImage1 from "../../../../static/assets/images/defaultCoverImage1.png";
import { InputBox } from "./SharedModules";

function Admin(props) {
  const { handleBlur, values, setFieldValue, handleChange } = props;

  const [focused, setFocused] = useState(false);

  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState(null);
  const [dragging, setDragging] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const dropRef = useRef(null);

  return (
    <VStack width={"100%"} alignItems={"flex-start"}>
        <HStack width={"100%"}>
      <InputBox
          values={values}
          handleBlur={handleBlur}
          handleChange={handleChange}
          placeholder="Portalys Event"
          label="Organizer Percentage fee"
          name="fee_percentage"
          type="number"
          maxW="100%"
          w={"100%"}
        />
         <InputBox
          values={values}
          handleBlur={handleBlur}
          handleChange={handleChange}
          placeholder="Portalys Event"
          label="Organizer Fee Fixed ($)"
          name="fee_fixed"
          type="number"
          maxW="100%"
          w={"100%"}
        />
        </HStack>

        <HStack width={"100%"}>
      <InputBox
          values={values}
          handleBlur={handleBlur}
          handleChange={handleChange}
          placeholder="Portalys Event"
          label="Participant Percentage fee"
          name="participant_fee_percentage"
          type="number"
          maxW="100%"
          w={"100%"}
        />
         <InputBox
          values={values}
          handleBlur={handleBlur}
          handleChange={handleChange}
          placeholder="Portalys Event"
          label="Participant Fee Fixed ($)"
          name="participant_fee_fixed"
          type="number"
          maxW="100%"
          w={"100%"}
        />
        </HStack>
    </VStack>
  );
}

export default Admin;
