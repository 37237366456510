import { useEffect } from "react";
import { useSearchParams, useLocation } from "react-router-dom";
import { useRecoilState } from "recoil";

import HomePage from "../modules/pages/Home/index";
import Layout from "../modules/layout/layout";
import {
  RegistraionModalTypes,
  registration,
} from "../recoil/atoms/registration";

/**
 * The Home function is a React component that renders the HomePage component within a Layout
 * component, and includes logic to handle a token parameter in the URL for resetting a password.
 * @param props - props is an object that contains the properties passed down to the Home component
 * from its parent component. These properties can be accessed and used within the component.
 * @returns The `Home` component is being returned.
 */
const Home = (props) => {
  let [searchParams, setSearchParams] = useSearchParams();
  const [_, setRegistrationModal] = useRecoilState(registration);
  const location = useLocation();
  const shouldShowContent = location.pathname.includes("/claimticket");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const token = searchParams.get("token");
    const tokenVerify = searchParams.get("tokenVerify");
    const verifier = searchParams.get("verifier");
    if (verifier) {
      setRegistrationModal((lp) => {
        return {
          ...lp,
          openModal: true,
          modalType: RegistraionModalTypes.VERIFIER,
          userData: {},
          userRole: "",
        };
      });
    } else if (shouldShowContent) {
      setRegistrationModal((lp) => {
        return {
          ...lp,
          openModal: true,
          modalType: RegistraionModalTypes.LOGIN,
          userData: {},
          userRole: "",
        };
      });
    }
  }, []);

  return (
    <Layout {...props}>
      <HomePage />
    </Layout>
  );
};

export default Home;
