import {
    Box,
    Heading,
    Text,
    Image,
  } from "@chakra-ui/react";
  import {  Carousel} from "antd";
  import { isEmpty } from "lodash";
  import { ReactComponent as DateRangeIcon } from "../../../static/assets/images/dateRangeIcon.svg";
  import { ReactComponent as LocationIcon } from "../../../static/assets/images/TypePlace.svg";
  import { AiOutlineHeart, AiFillHeart, AiOutlineClose } from "react-icons/ai";
  import { Icon } from "@chakra-ui/react";
  import exportIcon from "../../../static/assets/images/exportIcon.svg";
  import { HeartFilled } from "@ant-design/icons";

 export const IconTextComponent = ({ icon, title, description }) => {

    return (
      <Box
        display={"flex"}
        justifyContent={"flex-start"}
        alignItems={"center"}
        gap={"0.63rem"}
      >
        <Box
          width={"2.8125rem"}
          height={"2.8125rem"}
          bg="rgba(255, 255, 255, 0.05)"
          borderRadius={"100px"}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          {icon}
        </Box>
        <Box>
          <Text
            fontSize={"1rem"}
            className="gordita600"
            letterSpacing={"-0.01rem"}
            lineHeight={"1.5rem"}
            color="#FFFFFF"
            textTransform="capitalize"
          >
            {title}
          </Text>
          <Text
            fontSize={"0.875rem"}
            className="gordita400"
            color={"rgba(255, 255, 255, 0.60)"}
            lineHeight={"150%"}
            letterSpacing={"-0.00875rem"}
            maxW={"217px"}
            overflow={"hidden"}
            whiteSpace={"nowrap"}
            textOverflow={"ellipsis"}
          >
            {description}
          </Text>
        </Box>
      </Box>
    );
  };
export const EventInfoComponent = ({
    eventName,
    accentColor,
    startTime,
    endTime,
    startDate,
    city,
    location,
    numberOfLikes,
    hideLikes,
    seePerks,
    sawPerks,
    isMobile,
    moment,
    TimeFormat,
    margtop,
    by,
    people,
    simpleEmbed,
  }) => 
(
<Box
width={"100%"}
display="flex"
flexDirection={"column"}
justify="left"
alignItems="baseline"
gap={"1.5rem"}
marginTop={margtop}
>
<Heading
  color="#FBFAFB"
  fontWeight="700"
  fontSize={simpleEmbed?'20px':isMobile ? "23px" : "29px"}
>
  {eventName}
</Heading>

<Box
  display="flex"
  justifyContent="flex-start"
  alignItems="center"
  width={"100%"}
  flexWrap={"wrap"}
  gap={"1.56rem"}
>
  {startTime && startDate && (
    <IconTextComponent
      icon={<DateRangeIcon fill={accentColor} />}
      title={moment(startDate).format("ddd, D MMM")}
      description={`${moment(startTime, "h:mm A").format(
        TimeFormat
      )} - ${moment(endTime, "h:mm A").format(TimeFormat)}`}
    />
  )}
  <IconTextComponent
    icon={<LocationIcon fill={accentColor} />}
    title={city}
    description={ <a 
      href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(location)}`} 
      target="_blank" 
      rel="noopener noreferrer"
      style={{ color: accentColor, textDecoration: 'underline' }}
    >
    {location}
    </a>}
  />
</Box>
{numberOfLikes != null && numberOfLikes !== 0 && 
  !hideLikes &&
  !(seePerks && sawPerks) && (
    <Box
      display="inline-flex"
      height="2.1875rem"
      alignItems="center"
      gap="0.625rem"
      flexShrink={0}
      color={accentColor}
      fontFamily="Gordita"
      fontSize="1rem"
      lineHeight="160%" /* 1.6rem */
      textEdge="cap"
      leadingTrim="both"
    >
      <HeartFilled fill={accentColor} style={{ fontWeight: 400 }} />{" "}
      {/* Heart icon with fontWeight 400 */}
      Wishlisted {by}{" "}
      <span
        style={{
          color: accentColor,
          fontFamily: "Gordita",
          fontSize: "1rem",
          fontWeight: 700,
          lineHeight: "160%" /* 1.6rem */,
          textEdge: "cap",
          leadingTrim: "both",
        }}
      >
        {numberOfLikes}
      </span>{" "}
      {people}
    </Box>
  )}
</Box>
);


export const MobileImageHeader = ({
    handleCopy,
    handleLike,
    handleX
    

  }) => 
(

<Box
pos={"absolute"}
display={"flex"}
justifyContent={"space-between"}
alignItems={"center"}
left={"1rem"}
right={"1rem"}
top={"0.45rem"}
zIndex={1}
>
<Box
  width="2.75rem"
  height="2.75rem"
  flexShrink="0"
  borderRadius="6.25rem"
  border="1px solid rgba(255, 255, 255, 0.05)"
  bg={"rgba(0, 0, 0, 0.50)"}
  backdropFilter={"blur(150px)"}
  display={"flex"}
  justifyContent={"center"}
  alignItems={"center"}
  _hover={{ background: "#000" }}
  cursor="pointer"
  onClick={handleX}
>
  <Icon
    as={AiOutlineClose}
    color="white.100"
    w="1.5rem"
    h="1.5rem"
    cursor="pointer"
  />
</Box>
<Box
  display={"flex"}
  justifyContent={"flex-end"}
  alignItems={"center"}
  gap={16}
>
  <Box
    width="2.75rem"
    height="2.75rem"
    flexShrink="0"
    borderRadius="6.25rem"
    border="1px solid rgba(255, 255, 255, 0.05)"
    bg={"rgba(0, 0, 0, 0.50)"}
    backdropFilter={"blur(150px)"}
    display={"flex"}
    justifyContent={"center"}
    alignItems={"center"}
    _hover={{ background: "#000" }}
  >
    <Image
      src={exportIcon}
      color="white.100"
      w="1.5rem"
      h="1.5rem"
      cursor="pointer"
      onClick={handleCopy}
    />
  </Box>
  <Box
    width="2.75rem"
    height="2.75rem"
    flexShrink="0"
    borderRadius="6.25rem"
    border="1px solid rgba(255, 255, 255, 0.05)"
    bg={"rgba(0, 0, 0, 0.50)"}
    backdropFilter={"blur(150px)"}
    display={"flex"}
    justifyContent={"center"}
    alignItems={"center"}
  >
    <Icon
      as={AiOutlineHeart}
      color="white.100"
      w="1.5rem"
      h="1.5rem"
      cursor="pointer"
      onClick={handleLike}
    />
  </Box>
</Box>
</Box>
);

export const EventImageComponent = ({
    extraPictures,
    isTablet,
    eventImage,
  }) => 
(
<>
{!isEmpty(extraPictures) ? (
  <Box
    style={{
      maxWidth: "100%",
      maxHeight: "722px",
      width: "100%",
      borderRadius: "40px",
      objectFit: "fill",
      marginBottom: isTablet ? 0 : "2.81rem",
    }}
  >
    <Carousel
      style={{
        maxWidth: "100%",
        maxHeight: "722px",
        width: "100%",
        borderRadius: "40px",
        objectFit: "fill",
        marginBottom: isTablet ? 0 : "2.81rem",
      }}
    >
      <Box key="event-image" w="100%">
        <img
          style={{
            maxWidth: "100%",
            maxHeight: "722px",
            width: "100%",
            borderRadius: "40px",
            objectFit: "fill",
            marginBottom: isTablet ? 0 : "2.81rem",
          }}
          src={eventImage}
        />
      </Box>
      {extraPictures
        .split(",")
        .map((imageUrl, index) => (
          <Box key={index} w="100%">
            <img
              style={{
                maxWidth: "100%",
                maxHeight: "722px",
                width: "100%",
                borderRadius: "40px",
                objectFit: "fill",
                marginBottom: isTablet ? 0 : "2.81rem",
              }}
              src={imageUrl.trim()}
            />
          </Box>
        ))}
    </Carousel>
  </Box>
) : (
  <img
    src={eventImage}
    style={{
      maxWidth: "100%",
      maxHeight: "722px",
      width: "100%",
      borderRadius: "40px",
      objectFit: "fill",
      marginBottom: isTablet ? 0 : "2.81rem",
    }}
    alt={"event-image"}
  />
)}
</>
);

