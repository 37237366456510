import {
    Button,
  } from "@chakra-ui/react";
  import { theme } from "../../../styles/theme/base";

export const EnterGuestListButton = ({
    handleFunction,
    text,
  }) => (
    <Button
      fontWeight="700"
      textAlign="center"
      margin={"20px 0"}
      fontSize="1rem"
      borderRadius="40px"
      sx={theme.buttons.primary.shape}
      bg={"transparent"}
      color={theme.buttons.primary.default}
      className="gordita700"
      css={{
        width: "100%",
        border: "1px solid rgba(255, 255, 255, 0.20)",
        textTransform: "capitalize",
      }}
      _hover={{
        borderColor: "#FFF",
      }}
      _active={{
        backgroundColor: "rgba(255, 255, 255, 0.05)",
      }}
      _disabled={{
        borderColor: "rgba(255, 255, 255, 0.10)",
        color: "rgba(255, 255, 255, 0.4)",
      }}
      onClick={handleFunction}
    >
      {text}
    </Button>
  );

  export const GetYourTicketsButton =({
    handleFunction,
    isGetYourTicketsButtonDisabled,
    noOfTickets,
    noOfperks,
    isPublished,
    seePerks,
    sawPerks,
    perkLists,
    continueText,
    checkout,
    getYourTickets,
    isMobile
  }) => (
    <Button
      fontWeight="700"
      textAlign="center"
      margin={isMobile ? "0 20px" : "20px 0"}
      fontSize="1rem"
      borderRadius="40px"
      outline="none"
      sx={theme.buttons.primary.shape}
      bg={theme.buttons.primary.default}
      color={theme.buttons.primary.color}
      className="gordita700"
      css={{
        width: "100%",
      }}
      _hover={{
        bg: !isGetYourTicketsButtonDisabled && theme.buttons.primary.hover,
        boxShadow:
          !isGetYourTicketsButtonDisabled &&
          theme.buttons.primary.hoverBoxShadow,
        cursor: !isGetYourTicketsButtonDisabled ? "pointer" : "default",
      }}
      _active={{
        boxShadow:
          !isGetYourTicketsButtonDisabled &&
          theme.buttons.primary.activeBoxShadow,
      }}
      _disabled={{
        bg: theme.buttons.primary.disabled,
        color: theme.buttons.primary.colorDisabled,
      }}
      onClick={handleFunction}
      isDisabled={(noOfTickets === 0 && noOfperks===0)  || isPublished===false} // Add the isDisabled prop here
    >
      {!isPublished
        ? "Event has not been published yet!"
        : perkLists.length > 0 && !sawPerks
        ? continueText
        : seePerks && sawPerks
        ? checkout
        : getYourTickets}
    </Button>
  );

  export const AddToWishlist = ({
    handleFunction,
    isLiked,
    removeFrom,
    addTo,
  }) => (
    <Button
    fontWeight="700"
    textAlign="center"
    margin={"20px 0"}
    fontSize="1rem"
    borderRadius="40px"
    sx={theme.buttons.primary.shape}
    bg={"transparent"}
    color={theme.buttons.primary.default}
    className="gordita700"
    css={{
      width: "100%",
      border: "1px solid rgba(255, 255, 255, 0.20)",
      textTransform: "capitalize",
    }}
    _hover={{
      borderColor: "#FFF",
    }}
    _active={{
      backgroundColor: "rgba(255, 255, 255, 0.05)",
    }}
    _disabled={{
      borderColor: "rgba(255, 255, 255, 0.10)",
      color: "rgba(255, 255, 255, 0.4)",
    }}
    onClick={handleFunction}
  >
    {isLiked
      ? `${removeFrom}`
      : `${addTo}`}{" "}
    wishlist
  </Button>
  );

  export const ViewFullEventPage = ({
    handleFunction,
  }) => (
    <Button
      fontWeight="700"
      textAlign="center"
      margin={"20px 0"}
      fontSize="1rem"
      borderRadius="40px"
      sx={theme.buttons.tercary.shape}
      bg={"transparent"}
      color={theme.buttons.tercary.default}
      className="gordita700"
      css={{
        textDecoration: "underline",
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
      _hover={{
        borderColor: "#FFF",
      }}
      _active={{
        backgroundColor: "rgba(255, 255, 255, 0.05)",
      }}
      _disabled={{
        borderColor: "rgba(255, 255, 255, 0.10)",
        color: "rgba(255, 255, 255, 0.4)",
      }}
      onClick={handleFunction}
    >
      View Full Event Page  &#8599;
    </Button>
  );
