import React from "react";
import { Text, VStack } from "@chakra-ui/react";

import { useMediaQuery } from "../../../../utils/useMediaQuery";

function TextComponent({ title, subtitle, description, button }) {
  const isMobile = useMediaQuery("(max-width: 768px)");
  const isTablet = useMediaQuery("(max-width: 1024px)");

  return (
    <VStack
      justifyContent={"center"}
      alignItems={"flex-start"}
      width={isTablet ? "100%" : "30rem"}
      margin={"0 !important"}
    >
      <Text
        fontSize={"1rem"}
        className="gordita600"
        color={"rgba(255, 255, 255, 0.60)"}
        lineHeight={"160%"}
      >
        {subtitle}
      </Text>
      <Text
        fontSize={isMobile ? "1.75rem" : "2.375rem"}
        className="gordita600"
        letterSpacing={"-0.02375rem"}
        textTransform={"capitalize"}
        color={"#FFFFFF"}
        lineHeight={"150%"}
      >
        {title}
      </Text>
      <Text
        fontSize={isMobile ? "1.1875rem" : "1.3125rem"}
        className="gordita400"
        lineHeight={"160%"}
        pb={"0.52rem"}
      >
        {description}
      </Text>
      {button}
    </VStack>
  );
}

export default TextComponent;
