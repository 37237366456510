import {
    Box,
    Flex,
    HStack,
    Stack,
    Text,
    VStack,
    Image,
    CircularProgress,
    CircularProgressLabel,
    useColorModeValue,
    Button,
    Heading,
  } from "@chakra-ui/react";
  import { useEffect, useState } from "react";
  import { useMediaQuery } from "../../../utils/useMediaQuery";
  import secrets from "../../../secrets";
  import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
    BarChart,
    Bar,
  } from "recharts";
  
  import { useRecoilState } from "recoil";
  import { user } from "../../../recoil/atoms/user";
  import { userRoles } from "../../../utils/constants";
  import axios from "../../../utils/api/axios";
  import "jspdf-autotable";
  import { isEmpty } from "lodash";
  import { useNavigate } from "react-router-dom";
  import { useParams } from "react-router-dom";
import { getEventsByUUID } from "../../../utils/actions/event";
import getSymbolFromCurrency from "currency-symbol-map";


  const getColor = (percentage) => {
    if (percentage <= 33) {
      return { fill: "#690DFF", back: "#C2B8FF" };
    } else if (percentage > 33 && percentage <= 65) {
      return { fill: "#FF5C00", back: "#FB8340" };
    } else {
      return { fill: "#FF5C00", back: "#C2FFED" };
    }
  };
  const analytics = [
    {
      name: "Total Tickets Sold",
      quantity: "60",
      percentage: "+9.15%",
      type: "up",
    },
    {
      name: "Total Earnings",
      quantity: "$1156",
      percentage: "-0.56",
      type: "up",
    },
    {
      name: "Total Perks Sold",
      quantity: "354 ",
      percentage: "-11.01%",
      type: "up",
    },
    {
      name: "Sold Percentage",
      quantity: "23",
      percentage: "-1.48%",
      type: "down",
    },
    {
      name: "Total Likes",
      quantity: "23",
      percentage: "+1.48%",
      type: "up",
    },
    {
      name: "Event Page Views",
      quantity: "23",
      percentage: "+1.48%",
      type: "up",
    },
    {
      name: "Average Basket",
      quantity: "23",
      percentage: "+1.48%",
      type: "up",
    },
    {
      name: "Conversion Rate",
      quantity: "23",
      percentage: "+1.48%",
      type: "up",
    },
  ];
  


  const AnalyticsPerEventComponent = () => {
    const isMobile = useMediaQuery("(max-width: 768px)");
    const [chartType, setChartType] = useState("Tickets");
    const colorValue = useColorModeValue("black.100", "white.100");
    const [hover, setHover] = useState("");
    const [dashboard, setDashboard] = useState({});
    const [_] = useRecoilState(user);
    const [csvData, setCsvData] = useState([]);
    const [pdfData, setPdfData] = useState(null);
    const [chartData, setChartData] = useState({});
    const [financeChartsData, setFinanceChartsData] = useState({});
    const [ticketChartType, setTicketChartType] = useState("FOURWEEKCharts");
    const [financeChartType, setFinanceChartType] = useState("FOURWEEKCharts");
    const [ticketChartData, setTicketChartData] = useState({});
    const [collectableChartData, setCollectableChartData] = useState({});
    const [ticketsSold, setTicketsSold] = useState([]);
    const navigate = useNavigate();
    const [yAxisDomain, setYAxisDomain] = useState([0, "auto"]);
    const [yAxisDomain2, setYAxisDomain2] = useState([0, "auto"]);
    const organizerId=_.userData?.organizerId || _.userData?.userId;
    const params = useParams();
    const { id } = params;
    const [event,setEvent]=useState();


  
   
    const init = async () => {
        
      const uuid=id;
      const url ="/api/analyticsByEvent/" + uuid;
      axios
        .get(url, {
          headers: {
            "x-auth-token": _.token,
          },
        })
        .then((res) => {
          const data = res.data?.analytics;
          if (data) {  
            setDashboard({
              "Total Tickets Sold": data.noOfTickets,
              "Total Earnings":
    `${getSymbolFromCurrency(data?.currency)}` + Number(data.earnings)?.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }),

              "Total Perks Sold": data.perks,
              "Total Likes": data.numberOfLikes,
              "Event Page Views": data.numberOfViews,
              "Average Basket":
                Number(data.averageBasket),
            "Sold Percentage":
            (Number(data.percentage) * 100).toFixed(2) + "%",
            "Conversion Rate":
                Number(
                  Number(data.noOfTickets)/Number(data.numberOfViews)*100
                ).toFixed(2) + "%",
            });

          }
        })
        .catch((err) => console.error(err));
    };
    
    const init2 = async () => {
      try{
        
      const val = await getEventsByUUID(id);
      setEvent(val);

      }
        catch (err) {
           console.error(err);
        }
    };
  
    useEffect(() => {
      init();
      init2();
    }, []);
  
    return (
      <Box  w="100%" padding= "30px 30px 30px 30px"  >
        <Box   w="100%" textAlign="center">
<Heading>Data for event: {event?.eventName}</Heading>
</Box>
          <div style={{ padding: "30px 30px 30px 30px" }}>
          <Flex
            flexWrap="wrap"
            justifyContent="space-between"
            color="white.100"
            mt={12}
            w="100%"
          >
            {analytics.map(({ name, percentage, quantity, type }, index) => (
              <Box
                key={index}
                mb={10}
                className="gordita400"
                p={24}
                bg="#000000"
                borderRadius="10px"
                borderColor="rgba(255, 255, 255, 0.20)"
                borderWidth="1px"
                flexBasis={isMobile ? "100%" : "24%"}
              >
                <Text className="gordita700" fontSize={"1rem"}>
                  {name}
                </Text>
                <HStack
                  mt={12}
                  justifyContent="s pace-between"
                  alignItems="flex-end"
                  color="primary.100"
                >
                  <Text fontSize={24} className="gordita700">
                    {dashboard[name] || 0}
                  </Text>
                </HStack>
              </Box>
            ))}
          </Flex>
  
                </div>
      </Box>
    );
  };
  
  export default AnalyticsPerEventComponent;
  